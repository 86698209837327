import React from 'react';

const DokumentyDoPobrania = (props) => {
  return (
    <React.Fragment>
      <h3 className="page-header firstColor">Dokumenty do pobrania</h3>

      <ol className="firstColor">
        <li>
          <a target="_blank" href="/public-assets/luxmed2023/OWU.pdf">
            OWU
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="/public-assets/luxmed2023/Karta_produktu.pdf">
            Karta produktu
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.luxmed.pl/szpitale">
            Lista szpitali
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="/public-assets/luxmed2023/Ulotka_Pełna Opieka.pdf">
            Pełna Opieka
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="/public-assets/luxmed2023/Ulotka_Opieka Ortopedyczna.pdf">
            Opieka Ortopedyczna
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="/public-assets/luxmed2023/Ulotka_Opieka Ortopedyczna Plus.pdf">
            Opieka Ortopedyczna Plus
          </a>
        </li>
      </ol>
    </React.Fragment>
  );
};

export default DokumentyDoPobrania;
