import React from 'react';

export const FirstTable = (props) => {
  return (
    <React.Fragment>
      <table className="table table-bordered ">
        <tbody className="text-center">
          <tr className="table-primary">
            <th>RODZAJ ŚWIADCZENIA</th>
            <th colSpan={4}>WYSOKOŚĆ ŚWIADCZENIA (ZŁ)</th>
          </tr>
          <tr className="table-primary firstColor">
            <th className="col-sm-7">
              uszczerbek ustalany na podstawie Tabeli Norm Uszczerbku na Zdrowiu
              InterRisk - Załącznik nr 3 do OWU EDU Plus
            </th>
            <th>Ad 1</th>
            <th>Ad 2</th>
            <th>Ad 3</th>
            {/* <th>Ad 4</th> */}
          </tr>
          <tr>
            <td>
              śmierć Ubezpieczonego na terenie placówki oświatowej w wyniku nw
            </td>
            <td>
              <b>108 000</b>
            </td>
            <td>
              <b>140 000</b>
            </td>
            <td>
              <b>200 000</b>
            </td>
            {/* <td>
              <b>150 000</b>
            </td> */}
          </tr>
          <tr>
            <td>śmierć Ubezpieczonego w następstwie wypadku komunikacyjnego</td>
            <td>
              <b>32 000</b>
            </td>
            <td>
              <b>60 000</b>
            </td>
            <td>
              <b>70 000</b>
            </td>
            {/* <td>
              <b>70 000</b>
            </td> */}
          </tr>
          <tr>
            <td>
              śmierć Ubezpieczonego w wyniku nw (w tym również zawał serca i
              udar mózgu u dziecka)
            </td>
            <td>
              <b>27 000</b>
            </td>
            <td>
              <b>35 000</b>
            </td>
            <td>
              <b>50 000</b>
            </td>
            {/* <td>
              <b>50 000</b>
            </td> */}
          </tr>
          <tr>
            <td>100% uszczerbku na zdrowiu w wyniku nw</td>
            <td>
              <b>27 000</b>
            </td>
            <td>
              <b>35 000</b>
            </td>
            <td>
              <b>50 000</b>
            </td>
            {/* <td>
              <b>50 000</b>
            </td> */}
          </tr>
          <tr>
            <td>
              uszczerbek od 1% do 25% - wypłata 1% SU za 1% uszczerbku
              <br />
              <b>
                {' '}
                uszczerbek powyżej 25% - wypłata 2% SU za każdy 1% uszczerbku
              </b>
            </td>
            <td>
              <b>
                270
                <br />
                540
              </b>
            </td>
            <td>
              <b>
                350
                <br />
                700
              </b>
            </td>
            <td>
              <b>
                500
                <br />
                1000
              </b>
            </td>
          </tr>
          <tr>
            <td>
              koszty nabycia wyrobów medycznych wydawanych na zlecenie (zwrot
              kosztów za zakup np. stabilizatora, kołnierza ortopedycznego)
            </td>
            <td>
              <b>do 8 100</b>
            </td>
            <td>
              <b>do 10 500</b>
            </td>
            <td>
              <b>do 15 000</b>
            </td>
            {/* <td>
              <b>do 15 000</b>
            </td> */}
          </tr>
          <tr>
            <td>
              koszty zakupu lub naprawy{' '}
              <b>
                okularów korekcyjnych lub aparatu słuchowego lub aparatu
                ortodontycznego lub pompy insulinowej
              </b>{' '}
              w wyniku nw na terenie placówki oświatowej
            </td>
            <td colSpan={4}>
              <b>300</b>
            </td>
          </tr>
          <tr>
            <td>
              <b>koszty porady psychologa</b> w związku z nw lub poważną chorobą
              Ubezpieczonego, śmiercią rodzica lub opiekuna prawnego w wyniku nw
            </td>
            <td colSpan={4}>
              <b>500</b>
            </td>
          </tr>
          <tr>
            <td>koszty przekwalifikowania zawodowego osób niepełnosprawnych</td>
            <td>
              <b>do 8 100</b>
            </td>
            <td>
              <b>do 10 500</b>
            </td>
            <td>
              <b>do 15 000</b>
            </td>
            {/* <td>
              <b>do 15 000</b>
            </td> */}
          </tr>{' '}
          {/* <tr>
            <td>
              koszty zakupu lub naprawy okularów korekcyjnych lub aparatu
              słuchowego uszkodzonych w wyniku NW na terenie placówki oświatowej
            </td>
            <td colSpan={4}>200</td>
          </tr> */}
          <tr>
            <td>
              pogryzienie przez psa – bez konieczności pobytu w szpitalu
              <br />
              pokąsania, ukąszenia
            </td>
            <td>
              <b>270</b>
              <br />

              <b>540</b>
            </td>
            <td>
              <b>250</b>
              <br />
              <b>700</b>
            </td>
            <td>
              <b>500</b>
              <br />
              <b>1000</b>
            </td>
            {/* <td>
              <b>5 000</b>
            </td> */}
          </tr>
          <tr>
            <td>
              rozpoznanie <b>sepsy</b> u Ubezpieczonego będącego dzieckiem
            </td>
            <td>
              <b>2 700</b>
            </td>
            <td>
              <b>3 500</b>
            </td>
            <td>
              <b>5 000</b>
            </td>
            {/* <td>
              <b>5 000</b>
            </td> */}
          </tr>
          <tr>
            <td>śmierć rodzica lub opiekuna prawnego w następstwie nw</td>
            <td>
              <b>2 700</b>
            </td>
            <td>
              <b>3 500</b>
            </td>
            <td>
              <b>5 000</b>
            </td>
            {/* <td>
              <b>5 000</b>
            </td> */}
          </tr>
          <tr>
            <td>
              uszczerbek na zdrowiu w wyniku ataku <b>padaczki</b>
            </td>
            <td>
              <b>270</b>
            </td>
            <td>
              <b>350</b>
            </td>
            <td>
              <b>500</b>
            </td>
            {/* <td>
              <b>500</b>
            </td> */}
          </tr>
          <tr>
            <td>wstrząśnienie mózgu w wyniku nw</td>
            <td>
              <b>750</b>
            </td>
            <td>
              <b>350</b>
            </td>
            <td>
              <b>500</b>
            </td>
            {/* <td>
              <b>500</b>
            </td> */}
          </tr>
          <tr>
            <td>
              zatrucie pokarmowe lub nagłe zatrucie gazami, bądź porażenie
              prądem lub piorunem
            </td>
            <td>
              <b>540</b>
            </td>
            <td>
              <b>700</b>
            </td>
            <td>
              <b>1000</b>
            </td>
            {/* <td>
              <b>1 000</b>
            </td> */}
          </tr>
          <tr>
            <td>
              rozpoznanie u Ubezpieczonego chorób odzwierzęcych (bąblowica,
              toksoplazmoza, wścieklizna)
            </td>
            <td>
              <b>1 350</b>
            </td>
            <td>
              <b>1 750</b>
            </td>
            <td>
              <b>2 500</b>
            </td>
            {/* <td>
              <b>2 500</b>
            </td> */}
          </tr>
          <tr>
            <td>
              <b>koszty akcji poszukiwawczej dziecka</b>
            </td>
            <td>
              <b>2 700</b>
            </td>
            <td>
              <b>3 500</b>
            </td>
            <td>
              <b>5 000</b>
            </td>
            {/* <td>
              <b>2 500</b>
            </td> */}
          </tr>
          <tr>
            <td>
              <b>oparzenia i odmrożenia</b> w wyniku nw, II stopień - 10% SU;
              III stopień - 30% SU; IV stopień - 50% SU
            </td>
            <td>
              <b>do 2 500</b>
            </td>
            <td>
              <b>do 4 000</b>
            </td>
            <td>
              <b>do 5 000</b>
            </td>
            {/* <td>
              <b>do 5 000</b>
            </td> */}
          </tr>
          <tr>
            <td>
              <b>pobyt w szpitalu w wyniku nw</b> - świadczenie od
              <b> pierwszego</b> dnia pobytu w szpitalu minimum 2 dni
            </td>
            <td>
              <b>70</b>
            </td>
            <td>
              <b>80</b>
            </td>
            <td>
              <b>100</b>
            </td>
            {/* <td>
              <b>100</b>
            </td> */}
          </tr>
          <tr>
            <td>
              <b>pobyt w szpitalu w wyniku choroby</b> - świadczenie od{' '}
              <b>drugiego</b> dnia pobytu w szpitalu minimum 3 dni; w tym pobyt
              związany z <b>COVID-19</b>
            </td>
            <td>
              <b>60</b>
            </td>
            <td>
              <b>80</b>
            </td>
            <td>
              <b>100</b>
            </td>
            {/* <td>
              <b>100</b>
            </td> */}
          </tr>
          <tr>
            <td>
              operacje w wyniku nieszczęśliwego wypadku - zgodnie z Tabelą nr 8
              w OWU EDU PLUS
            </td>
            <td>
              <b>x</b>
            </td>
            <td>
              <b>do 2 000</b>
            </td>
            <td>
              <b>do 2 000</b>
            </td>
            {/* <td>
              <b>100</b>
            </td> */}
          </tr>
          <tr>
            <td>
              <b>poważne choroby</b> – zgodnie z definicją OWU
              <br />
              <small>
                wada wrodzona serca, nowotwór złośliwy, paraliż, niewydolność
                nerek, poliomyelitis, utrata wzroku, utrata mowy, utrata słuchu,
                anemia aplastyczna, stwardnienie rozsiane, cukrzyca typu I,
                niewydolność serca, zapalenie opon mózgowo-rdzeniowych,
                transplantacja głównych organów
              </small>
            </td>
            <td>
              <b>2 000</b>
            </td>
            <td>
              <b>2 000</b>
            </td>
            <td>
              <b>2 000</b>
            </td>
            {/* <td>
              <b>2 000</b>
            </td> */}
          </tr>
          <tr>
            <td>
              <b>koszty leczenia w wyniku nw</b>
              <br />
              wizyty lekarskie, zabiegi ambulatoryjne, zlecone badania, koszty
              pobytu w szpitalu, operacje, <b>operacje plastyczne</b>, zakup
              środków opatrunkowych, <b>rehabilitacja</b>, zwrot kosztów leków
              do 500 zł
            </td>
            <td>
              <b>do 4 000</b>
              <br />
              <small>
                do 4 000 za wyjątkiem kosztów: rehabilitacji do 2 000, operacji
                plastycznych do 8 000
              </small>
            </td>
            <td>
              <b>do 6 500</b>
              <br />
              <small>
                za wyjątkiem kosztów: rehabilitacji do 3 250, operacji
                plastycznych do 13 000
              </small>
            </td>
            <td>
              <b>do 8 000</b>
              <br />
              <small>
                za wyjątkiem kosztów: rehabilitacji do 4 000, operacji
                plastycznych do 16 000
              </small>
            </td>
            {/* <td>
              <b>do 8 000</b>
            </td> */}
          </tr>
          <tr>
            <td>koszty leczenia stomatologicznego w wyniku nw</td>
            <td>
              <b>do 800</b>
            </td>
            <td>
              <b>do 1 200</b>
            </td>
            <td>
              <b>do 1 200</b>
            </td>
            {/* <td>
              <b>do 1 000</b>
            </td> */}
          </tr>
          <tr>
            <td>
              czasowa niezdolność Ubezpieczonego do nauki lub do pracy w wyniku
              nw
            </td>
            <td>
              <b>x</b>
            </td>
            <td>
              <b>x</b>
            </td>
            <td>
              <b>20</b>
            </td>
            {/* <td>
              <b>20</b>
            </td> */}
          </tr>
          <tr>
            <td>zwrot kosztów pogrzebu Ubezpieczonego w wyniku nw</td>
            <td>
              <b>x</b>
            </td>
            <td>
              <b>x</b>
            </td>
            <td>
              <b>do 5 000</b>
            </td>
            {/* <td>
              <b>do 5 000</b>
            </td> */}
          </tr>
          <tr>
            <td>
              uciążliwe leczenie w wyniku nieszczęśliwego wypadku{' '}
              <b>– „bólowe”</b>
              <br />
              brak uszczerbku na zdrowiu, wymagane leczenie i co najmniej dwie
              stacjonarne, kontrolne wizyty lekarskie w placówce medycznej lub
              czasowa niezdolność do nauki lub pracy przez okres nie krótszy niż
              5 dni;
            </td>
            <td colSpan={4}>200</td>
          </tr>
          <tr></tr>
          <tr>
            <td>
              <b>pakiet KLESZCZ</b> i rozpoznanie boreliozy do 100% SU, w tym:
              a) zdiagnozowanie boreliozy do 1 000 zł, b) wizyta u lekarza do
              150 zł, c) badania diagnostyczne do 150 zł, d) antybiotykoterapia
              do 200 zł
            </td>
            <td colSpan={4}>do 1 500</td>
          </tr>
          <tr>
            <td>
              Assistance EDU PLUS (indywidualne korepetycje, pomoc medyczna,
              pomoc informatyczna)
            </td>
            <td colSpan={4}>5 000</td>
          </tr>
          <tr>
            <td style={{ color: 'red' }}>
              <b>Hejt Stop _ świadczenie zgodnie z OWU</b>
            </td>
            <td colSpan={4}>5 000</td>
          </tr>
          <tr>
            <td style={{ color: 'red' }}>
              {' '}
              <b>
                ochrona ubezpieczeniowa w związku z amatorskim i wyczynowym
                uprawianiem sportu
              </b>
            </td>
            <td colSpan={4} style={{ color: 'red' }}>
              <b>TAK</b>
            </td>
          </tr>
          <tr>
            <td>
              <b>Składka w zł (osoba/rok)</b>
            </td>
            <td>
              <b>59</b>
            </td>
            <td>
              <b>79</b>
            </td>
            <td>
              <b>110</b>
            </td>
            {/* <td>
              <b>107</b>
            </td> */}
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};
