import React from 'react';
// import photoA from './../../../assets/img/brand/photo1.png';
import photoB from './../../../assets/img/brand/photo11.png';
import logo from './../../../assets/img/brand/luxmed/interrisk.png';
import bar from './../../../assets/img/brand/luxmed/barImg.png';
import { Alert } from 'reactstrap';
import { FirstTable } from './FirstTable';
import { SecondTable } from './SecondTable';
import { Regulamin } from './Regulamin';

const isOrange = window.location.hostname === 'orange.uspartner.pl';
const isInsurances = window.location.hostname === 'ubezpieczenia.uspartner.pl';

// isOrange = true;
const Ogolne2 = (props) => {
  return (
    <React.Fragment>
      <img style={{ maxWidth: '250px' }} src={logo} alt={logo} />
      <br />
      <br />
      <br />

      <Alert color="secondary">
        <p className="text-justify">
          <b>Szanowni Państwo,</b>
          <br />
          <br />
          Dla wszystkich zainteresowanych, którzy chcieliby ubezpieczyć swoje
          dzieci (w wieku przedszkolnym, szkolnym i studentów do 26 roku życia)
          od następstw nieszczęśliwych wypadków przygotowaliśmy wspólnie z
          InterRisk możliwość indywidulanego zawierania ubezpieczenia.
        </p>
      </Alert>
      <h2 className="page-header mt-4">EDU Plus</h2>
      <h4>Wybierz program ochrony ubezpieczeniowej na rok 2024/2025</h4>
      <img src={bar} alt={bar} />
      <br />
      <br />
      <div className="container">
        <div class="row">
          <div class="col-md-6">
            <div style={{ border: '1px dashed black', padding: '10px' }}>
              {' '}
              <p>
                <b>
                  Oferta grupowego ubezpieczenia szkolnego EDU Plus
                  {/* dla dzieci */}
                </b>
              </p>
              {/* <h4 className="thirdColor">
                {isInsurances ? '' : 'PRACOWNIKÓW I WSPÓŁPRACOWNIKÓW '}
                {isOrange && 'GRUPY ORANGE'}
                {!isOrange && !isInsurances && 'TVP S.A.'}
              </h4> */}
            </div>
            {/* <h4>
              <b>Wzorowa ochrona</b>
            </h4>
            <h5>Nie tylko dla wzorowych uczniów</h5>
            <br />
            <p>
              Oferta ubezpieczenia dla osób w wieku do 20. roku życia.
              <br /> Szeroki zakres świadczeń – w pakiecie m. in.:
            </p>
            <br /> */}
            <br />
            <br />

            <ul>
              {/* <li>
                <b>Ochrona ubezpieczonego każdego dnia, 24 h/dobę,</b> w
                dowolnym miejscu na świecie
              </li>
              <br />
              <li>
                <b>Hejt Stop –</b> wsparcie psychologiczne, prawne i
                informatyczne, w zakresie mowy nienawiści i bezpieczeństwa w
                sieci.
              </li>
              <br />
              <li>
                <b>Assistance EDU Plus</b> (indywidualne korepetycje, pomoc
                medyczna).
              </li>
              <br />
              <li>
                <b>Wyczynowe uprawianie sportu</b>
              </li>
              <br />
              <li>
                <b>Pakiet Kleszcz</b>
              </li>
              <br />
              <li>
                <b>Zwrot kosztów wycieczki</b>
              </li>
              <br />
              <li>
                <b>Pobyt w szpitalu</b> w związku z chorobą lub nieszczęśliwym
                wypadkiem.
              </li> */}
              <li>
                <b>Ochrona 365 dni przez 24 h na dobę</b> - ochrona
                ubezpieczeniowa w ciągu całego roku szkolnego i wakacji na
                terenie całego świata
              </li>
              <li>
                <b>SPORT</b> – rekreacyjne i wyczynowe uprawianie sportu w
                zakresie, również za wynagrodzeniem
              </li>
              <li>
                <b>Kumulacja</b> – wypłaty świadczeń z opcji głównej i opcji
                dodatkowych sumują się
              </li>
              <li style={{ color: 'red' }}>
                <b>Wyższe świadczenie</b> w przypadku uszczerbku w wyniku NW
                powyżej 25%{' '}
              </li>
              <li>
                Pokrycie udokumentowanych kosztów <b>porady psychologa</b>
              </li>
              <li>
                <b>Brak karencji</b>
              </li>
              {/* <li>
                <b>Dodatkowe świadczenie</b> - w przypadku uszczerbku w wyniku
                NW powyżej 50%{' '}
              </li> */}

              <li>
                <b>Pakiet Kleszcz</b> - pokrycie kosztów badań diagnostycznych,
                antybiotykoterapii, usunięcia kleszcza oraz wypłata świadczenia
                za rozpoznanie boreliozy
              </li>
              <li>
                <b>Hejt Stop</b> – wsparcie psychologiczne, prawne i
                informatyczne, w zakresie mowy nienawiści i bezpieczeństwa w
                sieci
              </li>
              <li>
                <b>Koszty akcji poszukiwawczej dziecka</b> – pokrycie
                poniesionych kosztów przez rodzica lub opiekuna prawnego w
                związku z zaginięciem dziecka
              </li>
              <br />
              <li>
                infolinia czynna 24h / 7 dni w tygodniu <b>22 575 25 25</b>
              </li>
              <li>
                szybki, prosty i nowoczesny sposób zgłoszenia szkody on-line na
                stronie{' '}
                <a href="https://interrisk.pl/" target="_blank">
                  <b>www.interrisk.pl</b>
                </a>{' '}
              </li>
              <li>uproszczona procedura wypłaty odszkodowania</li>
            </ul>
          </div>

          <div class="col-md-6">
            <img src={photoB} alt="firstPhoto" />
          </div>
          <br />
          <br />

          {/* <p>
            <b>Ofertę przygotował:</b>
            <br />
            <b>II Oddział InterRisk TU SA Vienna Insurance Group w Warszawie</b>
          </p>
          <br />

          <small>
            Oferta przygotowana na podstawie Ogólnych Warunków Ubezpieczenia EDU
            Plus zatwierdzonych uchwałą nr 01/18/04/2023 Zarządu InterRisk TU
            S.A. Vienna Insurance Group z dnia 18 kwietnia 2023r. Wyłączenia i
            ograniczenia odpowiedzialności zawarte są w Ogólnych Warunkach
            Ubezpieczenia EDU Plus zatwierdzonych uchwałą nr 01/18/04/2023
            Zarządu InterRisk TU S.A. Vienna Insurance Group z dnia 18 kwietnia
            2023r. dostępnych na stronie www.interrisk.pl
          </small> */}
        </div>
      </div>
      {/* <br />
      <br />
      <h4 className="firstColor">EDU Plus</h4>
      <h5 className="firstColor mb-4">
        WYBIERZ PROGRAM OCHRONY UBEZPIECZENIOWEJ NA ROK 2023/2024
      </h5>
      {isOrange || isInsurances ? <FirstTableOrange /> : <FirstTable />}
      
      <SecondTable /> */}
      <br />
      <br />

      <FirstTable />
      <br />
      {/* <Regulamin /> */}
      <br />
      <SecondTable />
    </React.Fragment>
  );
};

export default Ogolne2;
