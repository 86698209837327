import React from 'react';
import photoC from './../../../assets/img/brand/luxmed/photo3.png';
import logo from './../../../assets/img/brand/luxmed/interrisk.png';

export const ThirdTable = (props) => {
  return (
    <React.Fragment>
      <br />
      <br />
      <table className="table table-bordered secondColor">
        <tbody className="text-center">
          <tr className="table-primary firstColor">
            <th>ZAKRES UBEZPIECZENIA</th>
            <th>Wariant I</th>
            <th>Wariant II</th>
            <th>Wariant III</th>
          </tr>
          <tr className="firstColor">
            <th>Rodzaj świadczenia</th>
            <th colSpan={3}>WYSOKOŚĆ ŚWIADCZENIA (%)</th>
            {/* <th>3</th>
            <th>4</th> */}
          </tr>
          <tr>
            <th>
              Śmierć Ubezpieczonego w wyniku NW (za NW uważa się również zawał
              serca i udar mózgu)
            </th>
            <th>30 000 zł</th>
            <th>50 000 zł</th>
            <th>100 000 zł</th>
          </tr>
          <tr>
            <th>
              Śmierć Ubezpieczonego w wyniku NW na terenie placówki oświatowej
              (świadczenie skumulowane ze świadczeniem za śmierć Ubezpieczonego
              w wyniku NW){' '}
            </th>
            <th>60 000 zł</th>
            <th>100 000 zł</th>
            <th>200 000 zł</th>
          </tr>
          <tr>
            <th>
              Uszczerbek na zdrowiu w wyniku NW (1% SU za 1% uszczerbku na
              zdrowiu, max. 100% SU) Uszczerbek na zdrowiu ustalany na podstawie
              Tabeli Norm Uszczerbku na Zdrowiu InterRisk– Załącznik nr 3 OWU
              EDU Plus
            </th>
            <th>300 zł/ 30 000zł</th>
            <th>500 zł/ 50 000 zł</th>
            <th>1 000zł/ 100 000 zł</th>
          </tr>
          <tr>
            <th>
              Koszty nabycia wyrobów medycznych wydawanych na zlecenie oraz
              koszty zakupu lub naprawy okularów lub aparatu słuchowego
              uszkodzonych w wyniku NW na terenie placówki oświatowej
            </th>
            <th>
              do 9000 zł
              <br /> do 200 zł
            </th>
            <th>
              do 15 000 zł
              <br /> do 200 zł
            </th>
            <th>
              do 30 000 zł
              <br /> do 200 zł
            </th>
          </tr>
          <tr>
            <th>Koszty przekwalifikowania zawodowego osób niepełnosprawnych</th>
            <th>do 9000 zł</th>
            <th>do 15 000 zł</th>
            <th>do 30 000 zł</th>
          </tr>
          <tr>
            <th>
              Uszczerbek na zdrowiu w wyniku ataku padaczki (jednorazowe
              świadczenie, warunek: padaczka rozpoznana po raz pierwszy w
              okresie ubezpieczenia){' '}
            </th>
            <th>300 zł</th>
            <th>500 zł</th>
            <th>1 000 zł</th>
          </tr>
          <tr>
            <th>
              Rozpoznanie sepsy u Ubezpieczonego będącego dzieckiem (jednorazowe
              świadczenie, warunek: sepsa rozpoznana po raz pierwszy w okresie
              ubezpieczenia)
            </th>
            <th>3 000 zł</th>
            <th>5 000 zł</th>
            <th>10 000 zł</th>
          </tr>{' '}
          <tr>
            <th>
              Śmierć opiekuna prawnego lub rodzica Ubezpieczonego w wyniku NW
              (wypłata za 2 zdarzenia w okresie ubezpieczenia)
            </th>
            <th>3 000 zł</th>
            <th>5 000 zł</th>
            <th>10 000 zł</th>
          </tr>{' '}
          <tr>
            <th>
              Pogryzienie przez psa (bez wymogu pobytu w szpitalu – jednorazowe
              świadczenie)
            </th>
            <th>300 zł</th>
            <th>500 zł</th>
            <th>1 000 zł</th>
          </tr>{' '}
          <tr>
            <th>
              Pokąsania, ukąszenia (wymóg pobytu w szpitalu 2 dni – jednorazowe
              świadczenie){' '}
            </th>
            <th>600 zł</th>
            <th>1 000 zł</th>
            <th>2 000 zł</th>
          </tr>{' '}
          <tr>
            <th>
              Wstrząśnienia mózgu w wyniku NW (wymóg pobytu w szpitalu min. 3
              dni){' '}
            </th>
            <th>300 zł</th>
            <th>500 zł</th>
            <th>1 000 zł</th>
          </tr>{' '}
          <tr>
            <th>
              Zatrucie pokarmowe, nagłe zatrucie gazami bądź porażenie prądem
              lub piorunem (wymóg pobytu w szpitalu 3 dni) – jednorazowe
              świadczenie{' '}
            </th>
            <th>600 zł</th>
            <th>1 000 zł</th>
            <th>2 000 zł</th>
          </tr>{' '}
          <tr>
            <th>
              Rozpoznanie u Ubezpieczonego chorób odzwierzęcych (bąblowicy,
              toksoplazmozy, wścieklizny) – jednorazowe świadczenie{' '}
            </th>
            <th>1 500 zł</th>
            <th>2 500 zł</th>
            <th>5 000 zł</th>
          </tr>
          <tr>
            <th>
              Opcja Hejt Stop (Usługi wsparcia informatycznego, psychologicznego
              oraz prawnego w zakresie mowy nienawiści i bezpieczeństwa w sieci)
            </th>
            <th>
              <b>TAK</b>
            </th>
            <th>
              <b>TAK</b>
            </th>
            <th>
              <b>TAK</b>
            </th>
          </tr>
          <tr className="table-primary firstColor">
            <th>Rodzaj świadczenia</th>
            <th colSpan={3}>WYSOKOŚĆ ŚWIADCZENIA (%)</th>
            {/* <th>3</th>
            <th>4</th> */}
          </tr>
          <tr>
            <th>
              Opcja Dodatkowa D1 – Śmierć Ubezpieczonego w następstwie wypadku
              komunikacyjnego (świadczenie łączne wraz ze świadczeniem za śmierć
              w wyniku NW){' '}
            </th>
            <th>60 000 zł</th>
            <th>100 000 zł</th>
            <th>160 000 zł</th>
          </tr>
          <tr>
            <th>Opcja Dodatkowa D2 – Oparzenia i odmrożenia</th>
            <th>
              II stopień – 600 zł
              <br />
              III stopień – 1 800 zł
              <br />
              IV stopień – 3 000 zł
            </th>
            <th>
              II stopień – 600 zł
              <br /> III stopień – 1 800 zł <br />
              IV stopień – 3 000 zł
            </th>
            <th>
              II stopień – 600 zł <br />
              III stopień – 1 800 zł
              <br />
              IV stopień – 3 000 zł
            </th>
          </tr>
          <tr>
            <th>
              Opcja Dodatkowa D3 – Pobyt w szpitalu w wyniku NW (świadczenie
              przysługuje od 1 dnia pobytu, pod warunkiem pobytu w szpitalu
              trwającego min. 2 dni)
            </th>
            <th>30zł/dzień</th>
            <th>40zł/dzień </th>
            <th>50zł/dzień</th>
          </tr>
          <tr>
            <th>
              Opcja Dodatkowa D4 – Pobyt w szpitalu w wyniku choroby
              (świadczenie przysługuje od 3 dnia pobytu w szpitalu){' '}
            </th>
            <th>30zł/dzień</th>
            <th>40zł/dzień </th>
            <th>50zł/dzień</th>
          </tr>
          <tr>
            <th>
              Opcja Dodatkowa D5 – Poważne Choroby (nowotwór złośliwy, paraliż,
              niewydolność nerek, poliomyelitis, utrata wzroku, utrata mowy,
              utrata słuchu, anemia aplastyczna, stwardnienie rozsiane, cukrzyca
              typu I, niewydolność serca, zapalenie opon mózgowo–rdzeniowych,
              transplantacja głównych organów)
            </th>
            <th>3 000 zł</th>
            <th>3 500 zł</th>
            <th>4 000 zł</th>
          </tr>
          <tr>
            <th>Opcja Dodatkowa D6 – Operacje w wyniku NW </th>
            <th>
              do 1000 zł <br />
              (zgodnie z Tab. nr 8 OWU)
            </th>
            <th>
              do 1000 zł <br />
              (zgodnie z Tab. nr 8 OWU)
            </th>
            <th>
              do 1000 zł <br />
              (zgodnie z Tab. nr 8 OWU)
            </th>
          </tr>
          <tr>
            <th>
              Opcja Dodatkowa D8 – Koszty leczenia w wyniku nieszczęśliwego
              wypadku
            </th>
            <th>
              do 3 000 zł
              <br /> w tym podlimit na:
              <br /> • zwrot kosztów rehabilitacji: 1 000 zł <br />• zwrot
              kosztów operacji plastycznych: 3 000 zł <br />• zwrot kosztów
              leków: 500 zł
            </th>
            <th>
              do 3 500 zł
              <br /> w tym podlimit na: <br />• zwrot kosztów rehabilitacji: 1
              000 zł <br />• zwrot kosztów operacji plastycznych: 3 500 zł{' '}
              <br />• zwrot kosztów leków: 500 zł
            </th>
            <th>
              do 5 000 zł
              <br /> w tym podlimit na: <br />• zwrot kosztów rehabilitacji: 1
              000 zł <br />• zwrot kosztów operacji plastycznych: 5 000 zł{' '}
              <br />• zwrot kosztów leków: 500 zł
            </th>
          </tr>
          <tr>
            <th>
              Opcja Dodatkowa D10 – Rozpoznanie u Ubezpieczonego wrodzonej wady
              serca
            </th>
            <th>1 000 zł</th>
            <th>1 000 zł</th>
            <th>1 000 zł</th>
          </tr>
          <tr>
            <th>
              Opcja Dodatkowa D11 – Koszty leczenia stomatologicznego w wyniku
              NW
            </th>
            <th>
              do 1 000 zł
              <br /> (max. 300 zł za każdy ząb)
            </th>
            <th>
              do 1 000 zł <br />
              (max. 300 zł za każdy ząb)
            </th>
            <th>
              do 1 000 zł <br />
              (max. 300 zł za każdy ząb)
            </th>
          </tr>
          <tr>
            <th>Opcja Dodatkowa D12 – Uciążliwe leczenie w wyniku NW </th>
            <th>200 zł</th>
            <th>200 zł</th>
            <th>200 zł</th>
          </tr>
          <tr>
            <th>
              Opcja Dodatkowa D13 – ASSISTANCE EDU PLUS (indywidualne
              korepetycje, pomoc medyczna){' '}
            </th>
            <th>5 000 zł</th>
            <th>5 000 zł</th>
            <th>5 000 zł</th>
          </tr>
          <tr>
            <th>
              Opcja Dodatkowa D17 – Pakiet KLESZCZ i rozpoznanie boreliozy
            </th>
            <th>
              1500 zł w tym: <br />• 1000 zł w przypadku rozpoznania boreliozy{' '}
              <br />• zwrot do 150zł – usunięcie kleszcza <br />• zwrot do 150
              zł – badania diagnostyczne potwierdzające lub wykluczające
              zakażanie boreliozą <br />• zwrot do 200 zł – antybiotykoterapia
              zalecona przez lekarza
            </th>
            <th>
              1500 zł w tym: <br />• 1000 zł w przypadku rozpoznania boreliozy{' '}
              <br />• zwrot do 150zł – usunięcie kleszcza • zwrot do 150 zł –
              badania diagnostyczne potwierdzające lub wykluczające zakażanie
              boreliozą <br />• zwrot do 200 zł – antybiotykoterapia zalecona
              przez lekarza
            </th>
            <th>
              1500 zł w tym: <br />• 1000 zł w przypadku rozpoznania boreliozy{' '}
              <br />• zwrot do 150zł – usunięcie kleszcza <br />• zwrot do 150
              zł – badania diagnostyczne potwierdzające lub wykluczające
              zakażanie boreliozą <br />• zwrot do 200 zł – antybiotykoterapia
              zalecona przez lekarza
            </th>
          </tr>
          <tr>
            <th>
              Opcja Dodatkowa D18 – Koszty opłaconej wycieczki szkolnej po NW
            </th>
            <th>do 500 zł</th>
            <th>do 500 zł</th>
            <th>do 500 zł</th>
          </tr>
          <tr>
            <th>
              <b>Wyczynowe uprawianie sportu:</b>
            </th>
            <th>
              <b>TAK</b>
            </th>
            <th>
              <b>TAK</b>
            </th>
            <th>
              <b>TAK</b>
            </th>
          </tr>
          <tr className="table-primary firstColor">
            <th>Składka roczna za osobę:</th>
            <th>69,00 zł</th>
            <th>120,00 zł</th>
            <th>189,00 zł</th>
          </tr>
        </tbody>
        {/* <tbody className="text-center">
          <tr className="table-primary">
            <th>
              <b>ZDARZENIA</b>
            </th>
            <th>
              <b>ZAKRES</b>
            </th>
            <th>
              <b>LIMIT ŚWIADCZEŃ</b>
            </th>
          </tr>
          <tr className="table-info">
            <td colSpan={12}>
              <b>Pomoc informatyczna</b>
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              <b>zwiększenie bezpieczeństwa na portalach społecznościowych</b>
            </td>
            <td className="text-left">
              <p>
                <b>Pomoc w zakresie:</b>
              </p>
              <ul>
                <li>
                  ustawienia hasła zgodnie z polityką bezpieczeństwa danego
                  portalu
                </li>
                <li>zabezpieczenia konta za pomocą weryfikacji dwuetapowej</li>
                <li>
                  przeanalizowania lokalizacji logowania do konta przez inne
                  osoby (nieautoryzowany dostęp)
                </li>
                <li>ustawienia prywatności (profile publiczne, prywatne)</li>
                <li>
                  usunięcia niepożądanych aplikacji, gier, witryn i reklam
                </li>
                <li>procesowania ewentualnych płatności w sieci</li>
                <li>
                  skonfigurowania powiadomień o nierozpoznanych logowaniach do
                  kont
                </li>
                <li>szyfrowania wiadomości z powiadomieniami</li>
                <li>
                  zdalnej pomocy specjalisty IT, polegającej na podjęciu próby
                  usunięcia lub zatrzymania dostępu do negatywnych lub
                  bezprawnie udostępnionych informacji o Ubezpieczonym na
                  urządzeniu Ubezpieczonego
                </li>
              </ul>
            </td>

            <td className="align-middle">
              4 zdarzenia w okresie ubezpieczenia
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              <b>włamanie na konto na portalu społecznościowym</b>
            </td>
            <td className="text-left">
              <p>
                <b>Przekazanie informacji:</b>
              </p>
              <ul>
                <li>jak postępować po kradzieży danych</li>
                <li>
                  jak zabezpieczyć pozostałe konta oraz dostępy, m.in. do
                  bankowości
                </li>
                <li>internetowej</li>
                <li>kogo zawiadomić o włamaniu</li>
                <li>o potrzebnych dokumentach, aby odzyskać profil</li>
                <li>jak unikać podobnych sytuacji w przyszłości</li>
              </ul>
            </td>
            <td className="align-middle">
              4 zdarzenia w okresie ubezpieczenia
            </td>
          </tr>
          <tr className="table-info">
            <td colSpan={12}>
              <b>Pomoc psychologiczna i pomoc prawna</b>
            </td>
          </tr>
          <tr className="text-left">
            <td>
              <ul>
                <b>
                  <li>znieważenie lub zniesławienie w sieci</li>
                  <li>
                    cyberbulling, cybermobbing (gnębienie w wirtualnej
                    przestrzeni)
                  </li>
                  <li>
                    agresywne, obraźliwe działania w sieci (np. komentarze lub
                    opinie na temat Ubezpieczonego)
                  </li>
                  <li>
                    rozpowszechnianie nieprawdziwych lub niepożądanych
                    prywatnych informacji w sieci lub rozpowszechnianie w sieci
                    zdjęć Ubezpieczonego
                  </li>
                  <li>
                    deepfake (czyli techniki obróbki obrazu, w której istnieje
                    możliwość dopasowania twarzy do innego ciała)
                  </li>
                  <li>szantaż</li>
                </b>
              </ul>
            </td>
            <td>
              <ul>
                <li>Pomoc psychologiczna - konsultacje psychologiczne</li>
                <li>
                  Pomoc prawna
                  <ul>
                    <li>
                      porady prawne, w procesie:
                      <ul>
                        <li>interwencyjnym</li>
                        <li>zgłoszenia przypadku do organów ścigania</li>
                        <li>zawezwania do zaprzestania działań</li>
                        <li>zawezwania do usunięcia wpisu lub komentarza</li>
                        <li>żądania blokady konta</li>
                        <li>mailowego lub społecznościowego</li>
                        <li>wezwania do naprawienia szkody</li>
                      </ul>
                    </li>
                    <li>
                      przekazanie wzoru pisma, przy:
                      <ul>
                        <li>sporządzeniu reklamacji, uzupełnieniu</li>
                        <li>
                          formularzy, powiadomień i zgłoszeń wymaganych do
                          powiadomienia o zaistnieniu zdarzenia w sieci
                        </li>
                        <li>
                          opracowaniu dokumentów do organów ścigania o
                          możliwości popełnienia przestępstwa
                        </li>
                        <li>
                          konkretyzacji roszczeń z tytułu zadośćuczynienia w
                          stosunku do sprawcy zachowania
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
            <td className="align-middle">
              2 zdarzenia
              <br /> w okresie ubezpieczenia, w tym w ramach jednego zdarzenia:
              <ul>
                <li>2 konsultacje psychologiczne po 30 min</li>
                <li>nielimitowane usługi z zakresu pomocy prawnej</li>
              </ul>
            </td>
          </tr>
        </tbody> */}
      </table>
    </React.Fragment>
  );
};
