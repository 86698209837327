import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import banerLuxmed from './../../../assets/img/brand/luxmed/banerluxmed.png';
import icon1 from './../../../assets/img/brand/luxmed/1.png';
import icon2 from './../../../assets/img/brand/luxmed/2.png';
import icon3 from './../../../assets/img/brand/luxmed/3.png';
import icon4 from './../../../assets/img/brand/luxmed/4.png';
import icon5 from './../../../assets/img/brand/luxmed/5.png';
import icon6 from './../../../assets/img/brand/luxmed/6.png';
import icon7 from './../../../assets/img/brand/luxmed/7.png';
import icon8 from './../../../assets/img/brand/luxmed/8.png';
import icon9 from './../../../assets/img/brand/luxmed/9.png';
import icon10 from './../../../assets/img/brand/luxmed/10.png';
import icon11 from './../../../assets/img/brand/luxmed/11.png';
import icon12 from './../../../assets/img/brand/luxmed/12.png';
import icon13 from './../../../assets/img/brand/luxmed/13.png';
import icon14 from './../../../assets/img/brand/luxmed/14.png';
import icon15 from './../../../assets/img/brand/luxmed/15.png';

const isNais =
  window.location.hostname === 'nais.uspartner.pl' ||
  window.location.hostname === 'renomia.uspartner.pl' ||
  window.location.hostname === 'ubezpieczenia.uspartner.pl';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <h3 className="page-header firstColor ">
        {isNais
          ? 'Ubezpieczenie szpitalne LUX MED'
          : 'Ubezpieczenie szpitalne LUX MED dla pracowników, byłych pracowników iwspółpracowników TVP i ich rodzin'}{' '}
        <span style={{ color: '#00ac34' }}>
          – mamy miejsce i czas, żeby pomagać
        </span>
      </h3>
      <br />
      <b className="firstColor mt-4">
        Co zyskujesz przystępując do prywatnego ubezpieczenia szpitalnego:{' '}
      </b>
      <ul className="firstColor">
        <li className="mt-2">
          Gwarancja szybkiego terminu realizacji świadczeń (do 30 dni) bez
          względu na opcję ubezpieczenia
        </li>
        <li className="mt-2">Komfortowe warunki leczenia</li>
        <li className="mt-2">
          Leczenie szpitalne bez troski o jego finalny koszt (brak sum
          ubezpieczenia)
        </li>
        <li className="mt-2">Szybka pomoc w nagłych przypadkach 24h/7</li>
        <li className="mt-2">
          Wsparcie formalne, merytoryczne i medyczne już od pierwszego kontaktu
        </li>
      </ul>
      <br />
      <b>
        Ofertą prywatnej opieki szpitalnej przygotowana przez LUX MED
        Ubezpieczenia dostępna dla Ciebie w jednym z trzech wariantów: Opieka
        Ortopedyczna, Opieka Ortopedyczna Plus i Pełna Opieka.{' '}
      </b>
      <br />
      <br />
      <b>Co wchodzi w zakres ubezpieczenia? </b>
      <br />
      <br />
      <table className="table table-condensed text-center secondColor">
        <tr
          style={{
            backgroundColor: '#00ac34',
            color: 'white',
            fontWeight: 'bolder',
            textAlign: 'center'
          }}>
          <td>Zakres świadczeń</td>
          <td>Opieka Ortopedyczna</td>
          <td>Opieka Ortopedyczna Plus</td>
          <td>Pełna Opieka</td>
        </tr>
        <tr>
          <td style={{ backgroundColor: '#d0dcec' }}>
            <b>Koordynacja Opieki Szpitalnej</b>
          </td>
          <td>
            <span>&#x2714;</span>
          </td>
          <td>&#x2714;</td>
          <td>&#x2714;</td>
        </tr>
        <tr
          style={{
            backgroundColor: '#00ac34',
            color: 'white',
            fontWeight: 'bolder',
            textAlign: 'center'
          }}>
          <td colSpan={4}>Świadczenia szpitalne</td>
        </tr>
        <tr>
          <td style={{ backgroundColor: '#d0dcec' }}>Hospitalizacja</td>
          <td>
            <b className="firstColor">Ortopedia</b>
            <br />
            (obejmuje zabiegi będące wyłącznie
            <br /> skutkiem Nieszczęśliwego Wypadku,
            <br /> realizowane w okresie do 30 dni od jego wystąpienia)
          </td>
          <td>
            <b className="firstColor">Ortopedia Planowa i Pilna</b>
          </td>
          <td>&#x2714;</td>
        </tr>
        <tr>
          <td style={{ backgroundColor: '#d0dcec' }}>
            Konsultacje psychologiczne
          </td>
          <td></td>
          <td></td>
          <td>&#x2714;</td>
        </tr>
        <tr>
          <td style={{ backgroundColor: '#d0dcec' }}>
            Świadczenia położniczo-neonatologiczne
          </td>
          <td></td>
          <td></td>
          <td>&#x2714;</td>
        </tr>
        <tr>
          <td style={{ backgroundColor: '#d0dcec' }}>
            Opieka medyczna przed Hospitalizacją
          </td>
          <td>&#x2714;</td>
          <td>&#x2714;</td>
          <td>&#x2714;</td>
        </tr>
        <tr>
          <td style={{ backgroundColor: '#d0dcec' }}>
            Opieka medyczna po Hospitalizacji
          </td>
          <td>&#x2714;</td>
          <td>&#x2714;</td>
          <td>&#x2714;</td>
        </tr>
        <tr>
          <td style={{ backgroundColor: '#d0dcec' }}>Rehabilitacja</td>
          <td></td>
          <td>&#x2714;</td>
          <td>&#x2714;</td>
        </tr>
        <tr>
          <td style={{ backgroundColor: '#d0dcec' }}>Opieka Doraźna</td>
          <td></td>
          <td>&#x2714;</td>
          <td>&#x2714;</td>
        </tr>
        <tr>
          <td style={{ backgroundColor: '#d0dcec' }}>
            <b>Szpitalny Przegląd Zdrowia co 2 lata</b>
          </td>
          <td></td>
          <td>&#x2714;</td>
          <td>&#x2714;</td>
        </tr>
      </table>
      <br />
      <h2 className="firstColor">
        Ubezpieczenia Szpitalne LUX MED - Opieka Ortopedyczna
      </h2>
      <br />
      <div className="container">
        <div className="row">
          <div
            className="col-sm-12 col-md-3 secondColor"
            style={{ padding: 0 }}>
            <div>
              <b>Opieka Ortopedyczna to:</b>
              <br></br>
              <ul>
                <li>
                  najwyższej jakości <b>opieka z zakresu ortopedii</b> pod okiem
                  wysokiej klasy specjalistów,
                </li>
                <li>
                  szeroki zakres <b>zabiegów,</b>
                </li>
                <li>
                  <b>diagnostyka,</b> konsultacje niezbędne przed, w trakcie i
                  po leczeniu szpitalnym,
                </li>
                <li>
                  <b>Koordynacja Opieki Szpitalnej.</b>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-9">
            <p className="firstColor">
              Wysokość miesięcznych składek dla Głównego Ubezpieczonego oraz
              członków jego rodziny w opcji Opieki Ortopedycznej wynosi:{' '}
            </p>
            <table className="table table-condensed table-striped">
              <tbody>
                <tr className="firstColor">
                  <td style={{ width: '33%' }} className="text-center">
                    <img src={icon13} alt="icon" className="smallIcon" />{' '}
                  </td>
                  <td style={{ width: '33%' }} className="text-center">
                    <img src={icon14} alt="icon" className="smallIcon" />{' '}
                  </td>
                  <td style={{ width: '33%' }} className="text-center">
                    <img src={icon15} alt="icon" className="smallIcon" />{' '}
                  </td>
                </tr>
              </tbody>
              <tr className="text-center">
                <td>Składka za Głównego Ubezpieczonego</td>
                <td>Składka za Partnera</td>
                <td>Składka za Dzieci</td>
              </tr>
              <tr
                className="text-center"
                style={{
                  background: '#e0e4f4',
                  color: '#003C7D',
                  fontWeight: 500
                }}>
                <td>61,00 PLN</td>
                <td>61,00 PLN</td>
                <td>36,00 PLN</td>
              </tr>
              <tr className="text-center">
                <td>składka miesięczna</td>
                <td>składka miesięczna</td>
                <td>składka miesięczna dla każdego Dziecka Niepełnoletniego</td>
              </tr>
              <tr className="text-center">
                <td></td>
                <td></td>
                <td
                  style={{
                    background: '#e0e4f4',
                    color: '#003C7D',
                    fontWeight: 500
                  }}>
                  58,00 PLN
                </td>
              </tr>
              <tr className="text-center">
                <td></td>
                <td></td>
                <td>składka miesięczna dla każdego Dziecka Pełnoletniego</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <br />
      <h2 className="firstColor">
        Ubezpieczenia Szpitalne LUX MED - Opieka Ortopedyczna Plus
      </h2>
      <br />
      <div className="container">
        <div className="row">
          <div
            className="col-sm-12 col-md-3 secondColor"
            style={{ padding: 0 }}>
            {' '}
            <div>
              <b>Opieka Ortopedyczna Plus to:</b>
              <br></br>
              <ul>
                <li>
                  najwyższej jakości <b>opieka z zakresu ortopedii</b> pod okiem
                  wysokiej klasy specjalistów,
                </li>
                <li>
                  szeroki zakres <b>zabiegów,</b>
                </li>
                <li>
                  <b>diagnostyka,</b> Konsultacje niezbędne przed, w trakcie i
                  po leczeniu szpitalnym,
                </li>
                <li>
                  pomoc w zakresie <b>hospitalizacji pilnej oraz planowej</b> z
                  obszaru ortopedii,
                </li>
                <li>
                  <b>rehabilitacja</b> po zabiegu,
                </li>
                <li>
                  <b>Szpitalny Przegląd Zdrowia,</b>
                </li>
                <li>Pilna Pomoc medyczna 24/7,</li>
                <li>
                  <b>Koordynacja Opieki Szpitalnej.</b>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-9">
            <p className="firstColor">
              Wysokość miesięcznych składek dla Głównego Ubezpieczonego oraz
              członków jego rodziny w opcji Opieki Ortopedycznej Plus wynosi:{' '}
            </p>
            <table className="table table-condensed table-striped">
              <tbody>
                <tr className="firstColor">
                  <td style={{ width: '33%' }} className="text-center">
                    <img src={icon13} alt="icon" className="smallIcon" />{' '}
                  </td>
                  <td style={{ width: '33%' }} className="text-center">
                    <img src={icon14} alt="icon" className="smallIcon" />{' '}
                  </td>
                  <td style={{ width: '33%' }} className="text-center">
                    <img src={icon15} alt="icon" className="smallIcon" />{' '}
                  </td>
                </tr>
              </tbody>
              <tr className="text-center">
                <td>Składka za Głównego Ubezpieczonego</td>
                <td>Składka za Partnera</td>
                <td>Składka za Dzieci</td>
              </tr>
              <tr
                className="text-center"
                style={{
                  background: '#e0e4f4',
                  color: '#003C7D',
                  fontWeight: 500
                }}>
                <td>125,00 PLN</td>
                <td>126,00 PLN</td>
                <td>69,00 PLN</td>
              </tr>
              <tr className="text-center">
                <td>składka miesięczna</td>
                <td>składka miesięczna</td>
                <td>składka miesięczna dla każdego Dziecka Niepełnoletniego</td>
              </tr>
              <tr className="text-center">
                <td></td>
                <td></td>
                <td
                  style={{
                    background: '#e0e4f4',
                    color: '#003C7D',
                    fontWeight: 500
                  }}>
                  118,00 PLN
                </td>
              </tr>
              <tr className="text-center">
                <td></td>
                <td></td>
                <td>składka miesięczna dla każdego Dziecka Pełnoletniego</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <br />
      <h2 className="firstColor">
        Ubezpieczenia Szpitalne LUX MED - Pełna Opieka
      </h2>
      <br />
      <div className="container">
        <div className="row">
          <div
            className="col-sm-12 col-md-3 secondColor"
            style={{ padding: 0 }}>
            {' '}
            <div>
              <b>Pełna Opieka to:</b>
              <br></br>
              <ul>
                <li>
                  <b>Koordynacja Opieki Szpitalnej</b>
                </li>
                <li>Pilna Pomoc Medyczna 24/7</li>
                <li>Zabiegi/operacje pilne oraz planowe</li>
                <li>
                  <b>Hospitalizacje, </b>które wymagaja diagnostyki i/lub
                  leczenia niazabiegowego w warunkach szpitalnych
                </li>
                <li>
                  <b>Opieka</b> medyczna po hospitalizacji
                </li>
                <li>
                  <b>Rehabilitacja</b> poszpitalna
                </li>
                <li>
                  <b>Porody</b>
                </li>
                <li>
                  <b>Wsparcie psychologiczne</b> w leczeniu onkologicznym
                </li>
                <li>
                  <b>Szpitalny Przegląd Zdrowia</b>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-9">
            <p className="firstColor">
              Wysokość miesięcznych składek dla Głównego Ubezpieczonego oraz
              członków jego rodziny w opcji kompleksowej Pełna Opieka wynosi:
            </p>
            <table className="table table-condensed table-striped">
              <tbody>
                <tr className="firstColor">
                  <td style={{ width: '33%' }} className="text-center">
                    <img src={icon13} alt="icon" className="smallIcon" />{' '}
                  </td>
                  <td style={{ width: '33%' }} className="text-center">
                    <img src={icon14} alt="icon" className="smallIcon" />{' '}
                  </td>
                  <td style={{ width: '33%' }} className="text-center">
                    <img src={icon15} alt="icon" className="smallIcon" />{' '}
                  </td>
                </tr>
              </tbody>
              <tr className="text-center">
                <td>Składka dla Głównego Ubezpieczonego</td>
                <td>Składka dla Partnera</td>
                <td>Składka dla Dzieci</td>
              </tr>
              <tr
                className="text-center"
                style={{
                  background: '#e0e4f4',
                  color: '#003C7D',
                  fontWeight: 500
                }}>
                <td>283,00 PLN</td>
                <td>287,00 PLN</td>
                <td>98,00 PLN</td>
              </tr>
              <tr className="text-center">
                <td>składka miesięczna</td>
                <td>składka miesięczna</td>
                <td>składka miesięczna dla każdego Dziecka Niepełnoletniego</td>
              </tr>
              <tr className="text-center">
                <td></td>
                <td></td>
                <td
                  style={{
                    background: '#e0e4f4',
                    color: '#003C7D',
                    fontWeight: 500
                  }}>
                  198,00 PLN
                </td>
              </tr>
              <tr className="text-center">
                <td></td>
                <td></td>
                <td>składka miesięczna dla każdego Dziecka Pełnoletniego</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <br />
      <br />
      <b className="firstColor">
        W zależności od problemu medycznego pomożemy Ci na oddziale m.in.:
      </b>
      <br />
      <br />
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon1} alt="icon" className="smallIcon" />
            <p class="text-center">Chorób wewnętrznych</p>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon2} alt="icon" className="smallIcon" />
            <p class="text-center">Ortopedycznym</p>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon3} alt="icon" className="smallIcon" />
            <p class="text-center">Chirurgii ogólnej</p>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon4} alt="icon" className="smallIcon" />
            <p class="text-center">Chirurgii naczyniowej</p>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon5} alt="icon" className="smallIcon" />{' '}
            <p class="text-center">Gikenkologicznym</p>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon6} alt="icon" className="smallIcon" />
            <p class="text-center">Laryngologicznym</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon7} alt="icon" className="smallIcon" />
            <p class="text-center">Urologicznym</p>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon8} alt="icon" className="smallIcon" />
            <p class="text-center">Okulistycznym</p>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon9} alt="icon" className="smallIcon" />
            <p class="text-center">Neurochirurgii kręgosłupa</p>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon10} alt="icon" className="smallIcon" />
            <p class="text-center">Chirurgii onkologicznej</p>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon11} alt="icon" className="smallIcon" />
            <p class="text-center">Kardiologicznym</p>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 flexIconsWithCaptions">
            <img src={icon12} alt="icon" className="smallIcon" />{' '}
            <p class="text-center">Położniczym i neonatologicznym</p>
          </div>
        </div>
      </div>
      <br />
      <br />

      <h2 className="firstColor">
        Ograniczenia odpowiedzialności Ubezpieczenia Szpitalne LUX MED
      </h2>
      <br />
      <span className="firstColor" style={{ fontSize: '1.5em' }}>
        Z Ubezpieczenia Szpitalne LUX MED możesz skorzystać:
      </span>
      <br />
      <br />
      <br />
      <table className="table table-bordered text-center">
        <tr
          className="firstColor"
          style={{ backgroundColor: '#d0dcec', fontWeight: 'bolder' }}>
          <td>od 1 dnia kolejnego miesiąca od daty przystąpienia</td>
          <td>po 3 miesiącach od daty przystąpienia</td>
          <td>po 10 miesiącach od daty przystąpienia</td>
          <td>po 12, 24, 36 miesiącach od daty przystąpienia</td>
        </tr>
        <tr
          className="firstColor"
          style={{ backgroundColor: '#d0dcec', fontWeight: 'bolder' }}>
          <td colSpan={4}>MOŻESZ SKORZYSTAĆ</td>
        </tr>
        <tr>
          <td className="secondColor" style={{ fontWeight: 'bolder' }}>
            W sytuacji nieszczęśliwego wypadku
            <br /> <br />Z pomocy doraźnej i leczenia, będącego jej kontynuacją
            <br />
            <br />Z pomocy Koordynatora Opieki Szpitalnej
          </td>
          <td className="text-left secondColor">
            <b>Z planowej hospitalizacji np.:</b>
            <br />
            <ul>
              <li>artroskopii stanu kolanowego,</li>
              <li>operacji przepukliny,</li>
              <li>operacji przypadków,</li>
              <li>operacji kamicy nerkowej,</li>
              <li>wycięcia migdałków,</li>
              <li>endoskopii, laparoskopii,</li>
              <li>operacji żylaków,</li>
              <li>
                z powodu dolegliwości, które nie mogły zostać zdiagnozowane
                ambulatoryjnie.
              </li>
            </ul>
          </td>
          <td className="secondColor text-left">
            <b>Z wysokospecjalistycznego leczenia i diagnostyki np.:</b>
            <ul>
              <li>chirurgii robotycznej</li>
              <li>funkcjonalnej endokopowej operacji zatok</li>
              <li>PET-CT, PET-MRI</li>
              <li>
                zabiegów, wymagających użycia materiałów wszczepialnych,
                implantów lub endoprotez
              </li>
            </ul>
            <br />
            <span>
              <b>Z porodu</b> (naturalnego lub cesarskiego cięcia):
              <li>
                data porodu musi być wyznaczona po upływie 10 miesięcy od
                momentu przystąpienia do ubezpieczenia
              </li>
            </span>
          </td>
          <td className="secondColor">
            <b>Z leczenia schorzeń, występujących przed ubezpieczeniem:</b>

            <li>
              Przystępujesz do ubezpieczenia <b>z ankietą medyczną.</b> Twój
              problem zdrowotny w zależności od schorzenia może zostać wyłączona
              na <b>12, 24, 36 miesięcy</b> lub będzie podglegał{' '}
              <b>wyłęczeniu stałemu</b>
              <br />
              <br />
              <br />
              <b>TO SĄ WYŁĄCZENIA</b>
            </li>
          </td>
        </tr>
      </table>
      <br />
      <br />

      <h4>Okresy karencji stosowane w Umowie wynoszą:</h4>
      <br />
      <ul>
        <li>3 miesiące - dla Hospitalizacji Planowych</li>
        <li>
          10 miesięcy – dla Wysokospecjalistycznych Metod Leczenia i Diagnostyki
          oraz Świadczeń położniczo-neonatologicznych
        </li>
      </ul>
      <br />
      <br />
      <span className="secondColor">
        „LUX MED Ubezpieczenia to nazwa handlowa zakładu ubezpieczeń LMG
        Försäkrings AB z siedzibą w Sztokholmie, wykonującego w Polsce
        działalność poprzez oddział LMG Försäkrings AB S.A. Oddział w Polsce z
        siedzibą w Warszawie. Szczegółowy zakres oraz warunki Ubezpieczenia
        Szpitalnego LUX MED znajdziesz w OWU dostępnym pod adresem:{' '}
        <a target="_blank" href="/public-assets/luxmed2023/OWU.pdf">
          <b>Zobacz OWU</b>
        </a>
        ”
      </span>
      <br />
      <br />
    </React.Fragment>
  );
};

export default Ogolne;
