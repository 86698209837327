import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './Dashboard.module.scss';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import lloydsLogo from '../../../src/assets/img/brand/coverholder-lloyds.png';

const isOrange = window.location.hostname === 'orange.uspartner.pl';
const isInsurances = window.location.hostname === '';
let isNais =
  window.location.hostname === 'nais.uspartner.pl' ||
  window.location.hostname === 'renomia.uspartner.pl' ||
  window.location.hostname === 'ubezpieczenia.uspartner.pl';

let displayCeu = window.location.hostname === 'renomia.uspartner.pl' && false;

const CardLink = (props) => {
  const { link, title, icon } = props;
  return (
    <Link to={link} className={classes.noDecoration}>
      <div className={classes.dashboardButton}>
        <div>
          <FontAwesomeIcon fixedWidth icon={icon} />
        </div>
        <div>{title}</div>
      </div>
    </Link>
  );
};

const links = {
  zagraniczne: {
    tvpubezpieczenia:
      'https://www.axa-assistance.pl/ubezpieczenie-turystyczne/?externalpartner=13000170',
    orange:
      'https://www.axa-assistance.pl/ubezpieczenie-turystyczne/?externalpartner=13000170',
    ubezpieczenia:
      'https://www.axa-assistance.pl/ubezpieczenie-turystyczne/?externalpartner=13000170',
    nais: 'https://www.axa-assistance.pl/ubezpieczenie-turystyczne/?externalpartner=13100174'
  },
  assistance: {
    tvpubezpieczenia:
      'https://www.axa-assistance.pl/ubezpieczenie-pojazdu/?ExternalPartner=13000170',
    orange:
      'https://www.axa-assistance.pl/ubezpieczenie-pojazdu/?ExternalPartner=13000170',
    ubezpieczenia:
      'https://www.axa-assistance.pl/ubezpieczenie-pojazdu/?ExternalPartner=13000170',
    nais: 'https://www.axa-assistance.pl/ubezpieczenie-pojazdu/?ExternalPartner=13100174'
  },
  szkolne: {
    tvpubezpieczenia: 'https://klient.interrisk.pl/EduPlusOnline?u=fkfbfwzrua',
    orange: 'https://klient.interrisk.pl/EduPlusOnline?u=fkfbfwzrua',
    ubezpieczenia: 'https://klient.interrisk.pl/EduPlusOnline?u=fkfbfwzrua',
    nais: 'https://klient.interrisk.pl/EduPlusOnline?u=fkfbfwzrua'
  }
};

const heightDiv =
  isOrange || isInsurances ? { height: '400px' } : { height: '276px' };

const Jumbotron = ({
  img,
  urlPrefix,
  insuranceName,
  logoPng,
  logoAlt,
  firstButtonText,
  secondButtonText,
  oneButton,
  isPzuRedirect,
  internal,
  externalLink,
  axa,
  axaAssistance,
  recommended,
  requiredRegistration,
  requiredRegistration2,
  noLogo,
  isCash,
  cashLogo,
  hasBorder,
  zagraniczne,
  assistance,
  szkolne,
  szkody,
  bottomSection
}) => {
  return (
    <div
      className="jumbotron jumbotron-fluid mb-4"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/tvp/dashboard/${img}.jpg')`,
        backgroundSize: 'auto', // lub 'auto 100%'
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: heightDiv.height
      }}>
      <div
        style={
          axa
            ? {
                position: 'absolute',
                top: '4px',
                right: '4px',
                backgroundColor: '#ffffff',
                padding: '12px'
              }
            : {
                position: 'absolute',
                top: '4px',
                right: '4px',
                backgroundColor: '#ffffff',
                padding: '12px'
              }
        }>
        {noLogo ? null : (
          <img
            style={{ height: '40px' }}
            src={process.env.PUBLIC_URL + `/tvp/${logoPng}.png`}
            alt={logoAlt}
          />
        )}
        {cashLogo && (
          <img
            style={{ height: '40px' }}
            src={process.env.PUBLIC_URL + `/tvp/${logoPng}.png`}
            alt={logoAlt}
          />
        )}
      </div>
      <div className="container mt-3">
        {urlPrefix ? (
          <Link
            to={`/${urlPrefix}/opis/`}
            style={{
              color: 'white'
            }}>
            {szkody ? (
              <h2
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  padding: '0',
                  borderRadius: '0.25rem',
                  width: 'fit-content'
                }}
                className={classes.smallFontSize}>
                {insuranceName}
              </h2>
            ) : (
              <h2
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  padding: '1rem',
                  borderRadius: '0.25rem',
                  width: 'fit-content'
                }}>
                {insuranceName}
              </h2>
            )}
          </Link>
        ) : (
          <a
            href="https://tvp-tuwpzuw.uspartner.pl/"
            target="_blank"
            style={{
              color: 'white'
            }}>
            <h2
              style={{
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.5)',
                padding: '1rem',
                borderRadius: '0.25rem',
                width: 'fit-content'
              }}>
              {insuranceName}
            </h2>
          </a>
        )}
        <p className="lead" style={{ display: 'flex' }}>
          {isPzuRedirect ? (
            <a
              href={
                isOrange || isInsurances
                  ? 'https://www.orange.pl/ubezpieczenia.phtml'
                  : 'https://tvp-tuwpzuw.uspartner.pl/'
              }
              target="_blank">
              <Button
                style={{
                  backgroundColor: '#003c7d',
                  border: '#003c7d'
                }}>
                {firstButtonText}
              </Button>
            </a>
          ) : (
            <a href={urlPrefix}>
              {!isCash ? (
                <Button
                  style={
                    isOrange || isInsurances
                      ? {
                          backgroundColor: '#ff6404',
                          border: '1px solid #ff6404',
                          margin: '0 2px'
                        }
                      : {
                          backgroundColor: '#28a745',
                          border: '1px solid #28a745',
                          margin: '0 2px'
                        }
                  }>
                  {firstButtonText}
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: '#003c7d',
                    border: '1px solid #003c7d',
                    margin: '0 2px'
                  }}>
                  {firstButtonText}
                </Button>
              )}
            </a>
          )}
          {oneButton && null}

          {internal && (
            <Button tag={Link} to={`/${urlPrefix}/kup/`}>
              {secondButtonText}
            </Button>
          )}

          {/* Zagraniczne */}
          {externalLink &&
            zagraniczne &&
            !isOrange &&
            !isInsurances &&
            !isNais && (
              <a href={links.zagraniczne.tvpubezpieczenia} target="_blank">
                <Button color="danger">
                  Kup ubezpieczenie
                  <br />
                </Button>
                <br />
              </a>
            )}

          {externalLink && zagraniczne && isOrange && (
            <a href={links.zagraniczne.orange} target="_blank">
              <Button color="danger">
                Kup ubezpieczenie
                <br />
              </Button>
              <br />
            </a>
          )}

          {externalLink && zagraniczne && isInsurances && (
            <a href={links.zagraniczne.ubezpieczenia} target="_blank">
              <Button color="danger">
                Kup ubezpieczenie
                <br />
              </Button>
              <br />
            </a>
          )}

          {externalLink && zagraniczne && isNais && (
            <a href={links.zagraniczne.nais} target="_blank">
              <Button color="danger">
                Kup ubezpieczenie
                <br />
              </Button>
              <br />
            </a>
          )}

          {/* Assistance */}
          {externalLink &&
            assistance &&
            !isOrange &&
            !isInsurances &&
            !isNais && (
              <a href={links.assistance.tvpubezpieczenia} target="_blank">
                <Button color="danger">
                  Kup ubezpieczenie
                  <br />
                </Button>
                <br />
              </a>
            )}

          {externalLink && assistance && isOrange && (
            <a href={links.assistance.orange} target="_blank">
              <Button color="danger">
                Kup ubezpieczenie
                <br />
              </Button>
              <br />
            </a>
          )}

          {externalLink && assistance && isInsurances && (
            <a href={links.assistance.ubezpieczenia} target="_blank">
              <Button color="danger">
                Kup ubezpieczenie
                <br />
              </Button>
              <br />
            </a>
          )}

          {externalLink && assistance && isNais && (
            <a href={links.assistance.nais} target="_blank">
              <Button color="danger">
                Kup ubezpieczenie
                <br />
              </Button>
              <br />
            </a>
          )}

          {/* Szkolne */}
          {externalLink && szkolne && !isOrange && !isInsurances && !isNais && (
            <a href={links.szkolne.tvpubezpieczenia} target="_blank">
              <Button color="danger">
                Kup ubezpieczenie
                <br />
              </Button>
              <br />
            </a>
          )}

          {externalLink && szkolne && isOrange && (
            <a href={links.szkolne.orange} target="_blank">
              <Button color="danger">
                Kup ubezpieczenie
                <br />
              </Button>
              <br />
            </a>
          )}

          {externalLink && szkolne && isInsurances && (
            <a href={links.szkolne.ubezpieczenia} target="_blank">
              <Button color="danger">
                Kup ubezpieczenie
                <br />
              </Button>
              <br />
            </a>
          )}

          {externalLink && szkolne && isNais && (
            <a href={links.szkolne.nais} target="_blank">
              <Button color="danger">
                Kup ubezpieczenie
                <br />
              </Button>
              <br />
            </a>
          )}

          {/* Szkody */}
          {externalLink && szkody && (
            <a
              href="https://www.uniqueservicepartner.pl/kontakt/"
              target="_blank">
              <Button color="danger">
                Kontakt
                <br />
              </Button>
              <br />
            </a>
          )}
        </p>
      </div>

      {requiredRegistration ? (
        <div className={classes.badgeRegistration}>
          Wymagana oddzielna rejestracja i logowanie
        </div>
      ) : null}
      {recommended ? (
        <div className={classes.badgeRegistration}>Polecane przez PZU Cash</div>
      ) : null}
      {requiredRegistration2 ? (
        <div className={classes.badgeRegistration2}>
          Przed pierwszym zalogowaniem należy się zarejestrować.
        </div>
      ) : null}
      {hasBorder ? <div className={classes.badge3}>Polska i Europa</div> : null}
      {bottomSection && (
        <div className={classes.badge4}>
          <img style={{ width: '150px' }} src={lloydsLogo} alt={lloydsLogo} />
        </div>
      )}
    </div>
  );
};

const Dashboard = (props) => (
  <React.Fragment>
    {props.isAuthenticated && props.luxmed && (
      <React.Fragment>
        <Row>
          <Col xs={6} md={3}>
            <CardLink
              link="/c3d881ba-9109-4c24-8e92-45d2bf8b5609"
              title="Moje wnioski"
              icon="file-signature"
            />
          </Col>
        </Row>
      </React.Fragment>
    )}

    <Row>
      <Col>
        <h5 style={{ marginBottom: '1rem' }}>
          {isOrange || isInsurances || isNais
            ? 'Dostępne ubezpieczenia:'
            : 'Dostępne ubezpieczenia i produkty finansowe:'}
        </h5>
      </Col>
    </Row>
    <Row>
      <div className="container">
        <div className="row">
          {!isOrange && !isInsurances && (
            <div className="col-xl-4 col-md-6 col-sm-12 p-1">
              <Jumbotron
                img={17}
                insuranceName={'Opieka szpitalna'}
                logoPng={'luxmedLogo'}
                logoAlt={'Luxmed logo'}
                firstButtonText={'Zobacz opis'}
                secondButtonText={'Zgłoś chęć ubezpieczenia'}
                urlPrefix={'pracownicy'}
                internal={true}
                requiredRegistration2={true}
              />
            </div>
          )}
          <div
            className={
              isOrange || isInsurances
                ? 'col-xl-6 col-md-6 col-sm-12 p-1'
                : 'col-xl-4 col-md-6 col-sm-12 p-1'
            }>
            <Jumbotron
              img={20}
              insuranceName={'Podróże zagraniczne'}
              logoPng={'axa_logo'}
              logoAlt={'AXA logo'}
              firstButtonText={'Zobacz opis'}
              secondButtonText={'Wykup polisę'}
              urlPrefix={'zagraniczne'}
              externalLink={true}
              axa={true}
              zagraniczne={true}
            />
          </div>
          <div
            className={
              isOrange || isInsurances
                ? 'col-xl-6 col-md-6 col-sm-12 p-1'
                : 'col-xl-4 col-md-6 col-sm-12 p-1'
            }>
            <Jumbotron
              img={21}
              insuranceName={'Moto Assistance'}
              logoPng={'axa_logo'}
              logoAlt={'AXA logo'}
              firstButtonText={'Zobacz opis'}
              secondButtonText={'Wykup polisę'}
              urlPrefix={'assistance'}
              externalLink={true}
              axa={true}
              axaAssistance={true}
              hasBorder={true}
              assistance={true}
            />
          </div>

          {!isOrange && !isInsurances && !isNais && (
            <div className="col-xl-4 col-md-6 col-sm-12 p-1">
              <Jumbotron
                img={13}
                insuranceName={'Zdrowotne'}
                logoPng={'tuw_logo'}
                logoAlt={'Tuw logo'}
                firstButtonText={
                  isOrange || isInsurances
                    ? 'Dostępne na Orange Ubezpieczenia'
                    : 'Dostępne na tvp-tuwpzuw.uspartner.pl'
                }
                oneButton={true}
                // urlPrefix={'/'}
                isPzuRedirect={true}
                requiredRegistration={true}
                noLogo={isOrange}
              />
            </div>
          )}
          <div
            className={
              isOrange || isInsurances
                ? 'col-xl-6 col-md-6 col-sm-12 p-1'
                : 'col-xl-4 col-md-6 col-sm-12 p-1'
            }>
            <Jumbotron
              img={10}
              insuranceName={'NNW szkolne'}
              logoPng={'interrisk'}
              logoAlt={'Interrisk logo'}
              firstButtonText={'Zobacz opis'}
              secondButtonText={'Wykup polisę'}
              urlPrefix={'nnw'}
              externalLink={true}
              szkolne={true}
            />
          </div>

          {!isOrange && !isInsurances && (
            <div className="col-xl-4 col-md-6 col-sm-12 p-1">
              <Jumbotron
                img={11}
                insuranceName={'Majątkowe'}
                logoPng={'interrisk'}
                logoAlt={'Interrisk logo'}
                firstButtonText={'Zobacz opis'}
                secondButtonText={'Wybierz wariant'}
                urlPrefix={'majatkowe'}
                internal={true}
                requiredRegistration2={true}
              />
            </div>
          )}

          {/* {!isOrange && !isInsurances && !isNais && (
            <div className="col-xl-4 col-md-6 col-sm-12 p-1">
              <Jumbotron
                img={14}
                insuranceName={'Finanse'}
                logoPng={'cash'}
                logoAlt={'PZU Cash logo'}
                firstButtonText={'Sprawdź pożyczki, kredyty i inne produkty'}
                urlPrefix={'finanse'}
                recommended
                isCash
                noLogo
                cashLogo
              />
            </div>
          )} */}
          {!isOrange && !isInsurances && (
            <div className="col-xl-4 col-md-6 col-sm-12 p-1">
              <Jumbotron
                img={22}
                insuranceName={'Szkody komunikacyjne OC/AC Pomoc'}
                logoPng={'usp'}
                logoAlt={'USP Logo'}
                firstButtonText={'Likwidacja szkód'}
                urlPrefix={'likwidacja'}
                // recommended
                // isCash
                noLogo
                cashLogo
                externalLink={true}
                szkody={true}
              />
            </div>
          )}
          {(isOrange || isInsurances) && (
            <div className="'col-xl-6 col-md-6 col-sm-12 p-1'">
              <Alert color="secondary">
                W sprawie oferty na inne prywatne ubezpieczenia np. majątkowe
                (mieszkanie, dom) lub komunikacyjne prosimy o kontakt:
                <br />
                <b>Infolinia:</b> 22 417 34 40
                <br />
                <b>E-mail:</b>{' '}
                {isOrange ? 'orange@uspartner.pl' : 'biuro@uspartner.pl'}
              </Alert>
            </div>
          )}
          {displayCeu && (
            <div className="col-xl-4 col-md-6 col-sm-12 p-1">
              <Jumbotron
                img={71}
                insuranceName={'Ubezpieczenie utraty dochodu'}
                logoPng={'ceu_logo'}
                firstButtonText={'Zobacz opis'}
                secondButtonText={'Wybierz wariant'}
                urlPrefix={'ceu'}
                internal={true}
                szkody={true}
                bottomSection={true}
              />
            </div>
          )}
        </div>{' '}
      </div>
    </Row>
  </React.Fragment>
);

const mapStateToProps = (state) => {
  const programs = state.auth.programs || [];
  return {
    isAuthenticated: state.auth.token !== null,

    username: state.auth.user.name,
    luxmed:
      state.auth.user.programId === 'f1912316-3516-44a0-b58b-4c24b584f486',
    hasPrograms: programs.length > 1 ? true : false,
    menu: state.menu.data && state.menu.data.menu ? state.menu.data.menu : []
  };
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         logout: () => dispatch(logout())
//     };
// }

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Dashboard);
