import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from '../../../store/actions/index';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

let isOrange = window.location.hostname === 'orange.uspartner.pl';
let isInsurances = window.location.hostname === 'ubezpieczenia.uspartner.pl';
let isNais = window.location.hostname === 'nais.uspartner.pl';
let isRenomia = window.location.hostname === 'renomia.uspartner.pl';
let displayCeu = window.location.hostname === 'renomia.uspartner.pl' && false;

let opis =
  'Specjalna oferta zdrowotna i ubezpieczeniowa dla pracowników i współpracowników TVP';

let navbarBackorund = { backgroundColor: '#003c7d' };

let iconsColor = { color: '#ffffff' };

if (isOrange || isInsurances) {
  opis =
    'Oferta ubezpieczeniowa dla pracowników i współpracowników Grupy Orange';
  navbarBackorund = {
    backgroundColor: '#003c7d',
    fontSize: '2rem'
  };
  iconsColor = { color: '#ff6404' };
}

if (isInsurances) {
  opis = 'Specjalna oferta ubezpieczeniowa';
}

if (isNais) {
  opis = 'Specjalna oferta zdrowotna i ubezpieczeniowa';
  navbarBackorund = {
    backgroundColor: '#ffffff'
    // borderBottom: '2px solid #39AA2C'
  };
  iconsColor = { color: '#39AA2C' };
}

if (isRenomia) {
  opis = 'Specjalna oferta zdrowotna i ubezpieczeniowa';
  navbarBackorund = {
    backgroundColor: '#ffffff'
    // borderBottom: '2px solid #39AA2C'
  };
  iconsColor = { color: '#003865' };
}

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  close = () =>
    this.setState({
      isOpen: false
    });

  render() {
    const { hasPrograms, isAuthenticated, luxmed } = this.props;

    const isTvpTuw =
      window.location.hostname === 'tvp-tuwpzuw.uspartner.pl' &&
      new Date('2022-10-24').getTime() > new Date().getTime();

    let profileMenu = null;

    const generateMenu = this.props.menu.map((item) => {
      if (item && item.displayName === 'Profile') {
        profileMenu = item.nestedElements.map((nestedItem) => (
          <Link
            onClick={this.close}
            to={`/${nestedItem.pageId}`}
            key={nestedItem.nodeId}>
            <DropdownItem tag="div" className="userDropdownItem">
              <div className="userDropIcon">
                <FontAwesomeIcon
                  fixedWidth
                  icon={nestedItem.materialIcon || 'dot-circle'}
                />
              </div>
              <div className="userName">{nestedItem.displayName}</div>
            </DropdownItem>
          </Link>
        ));
        return null;
      }
      if (item.nestedElements) {
        return (
          <UncontrolledDropdown key={item.nodeId} nav inNavbar>
            <DropdownToggle
              nav
              caret
              style={{
                color:
                  (isNais && (isRenomia ? '#003865' : '#212529')) || '#ffffff'
              }}>
              {item.displayName}
            </DropdownToggle>
            <DropdownMenu>
              {item.nestedElements.map((nestedItem) => (
                <Link
                  onClick={this.close}
                  to={`/${nestedItem.pageId}`}
                  key={nestedItem.nodeId}>
                  <DropdownItem>{nestedItem.displayName}</DropdownItem>
                </Link>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      } else if (item.pageId) {
        return (
          <NavItem key={item.nodeId}>
            <NavLink
              onClick={this.close}
              tag={RouterNavLink}
              to={`/${item.pageId}`}>
              {item.displayName}
            </NavLink>
          </NavItem>
        );
      }
      return null;
    });
    // const generateMenu = null;

    return (
      <div style={navbarBackorund} className="fixedTop">
        <Navbar dark expand="md" className="fixedWidth">
          <NavbarBrand tag={Link} onClick={this.close} to="/">
            {(!isAuthenticated || luxmed) && (
              <React.Fragment>
                {(isOrange || isInsurances) && (
                  <img
                    style={{
                      height: '55px',
                      backgroundColor: 'white',
                      marginLeft: '30px'
                    }}
                    src={process.env.PUBLIC_URL + '/usp567noborder.png'}
                    alt="USP logo"
                  />
                )}

                {!isOrange && !isInsurances && !isNais && !isRenomia && (
                  <img
                    style={{ height: '50px' }}
                    src={process.env.PUBLIC_URL + '/tvp/tvp-logo.png'}
                    alt="TVP logo"
                  />
                )}

                {isNais && (
                  <img
                    style={{ height: '50px', marginLeft: '30px' }}
                    src={process.env.PUBLIC_URL + '/nais.png'}
                    alt="Nais logo"
                  />
                )}
                {isRenomia && (
                  <img
                    style={{ height: '50px', marginLeft: '30px' }}
                    src={process.env.PUBLIC_URL + '/renomia.png'}
                    alt="Renomia logo"
                  />
                )}
              </React.Fragment>
            )}
            {isAuthenticated && !luxmed && (
              <img
                style={{
                  height: '50px',
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  marginRight: '0.5rem'
                }}
                src="/public-assets/usp.png"
                alt="USP  logo"
              />
            )}
          </NavbarBrand>

          <NavbarToggler onClick={this.toggle} />

          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar>
              {(!isAuthenticated || luxmed) && (
                <React.Fragment>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      nav
                      caret
                      style={
                        isOrange || isInsurances
                          ? { fontSize: '1.1em', marginLeft: '10px' }
                          : isNais || isRenomia || isInsurances
                          ? isNais
                            ? {
                                color: '#39AA2C',
                                marginLeft: '30px',
                                fontSize: '1.2em',
                                fontWeight: 'bolder'
                              }
                            : {
                                color: '#003865',
                                marginLeft: '30px',
                                fontSize: '1.2em',
                                fontWeight: 'bolder'
                              }
                          : null
                      }>
                      {opis}
                    </DropdownToggle>
                    <DropdownMenu
                      style={
                        isNais || isRenomia ? { marginLeft: '30px' } : null
                      }>
                      {!isOrange && (
                        <Link onClick={this.close} to="/pracownicy">
                          <DropdownItem>Opieka szpitalna</DropdownItem>
                        </Link>
                      )}

                      <Link onClick={this.close} to="/zagraniczne">
                        <DropdownItem>Podróże zagraniczne</DropdownItem>
                      </Link>

                      <Link onClick={this.close} to="/assistance">
                        <DropdownItem>Moto Assistance</DropdownItem>
                      </Link>

                      <Link onClick={this.close} to="/nnw">
                        <DropdownItem>NNW szkolne</DropdownItem>
                      </Link>

                      {!isOrange && (
                        <Link onClick={this.close} to="/majatkowe">
                          <DropdownItem>Majątkowe</DropdownItem>
                        </Link>
                      )}

                      {displayCeu && (
                        <Link onClick={this.close} to="/ceu">
                          <DropdownItem>
                            Ubezpieczenie utraty dochodu
                          </DropdownItem>
                        </Link>
                      )}

                      {/* {!isOrange && !isInsurances && !isNais && !isRenomia && (
                        <Link onClick={this.close} to="/finanse">
                          <DropdownItem>Finanse</DropdownItem>
                        </Link>
                      )} */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </React.Fragment>
              )}

              {generateMenu}
            </Nav>
            <Nav className="ml-auto" navbar>
              {this.props.isAuthenticated && (
                <React.Fragment>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      <FontAwesomeIcon
                        fixedWidth
                        icon="user-circle"
                        style={
                          isNais || isRenomia
                            ? isNais
                              ? { color: '#212529' }
                              : { color: '#003865' }
                            : { color: '#ffffff' }
                        }
                      />
                      <span
                        className="navbarUsername"
                        style={
                          isNais || isRenomia
                            ? isNais
                              ? { color: '#212529' }
                              : { color: '#003865' }
                            : { color: '#ffffff' }
                        }>
                        {this.props.username}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right className="userDropdown">
                      <div className="userDropdownItem profile">
                        <div className="userDropIcon">
                          <FontAwesomeIcon fixedWidth icon="user-circle" />
                        </div>
                        <div className="userName">{this.props.username}</div>
                      </div>
                      {hasPrograms && (
                        <Link onClick={this.close} to="/changeprogram/">
                          <DropdownItem tag="div" className="userDropdownItem">
                            <div className="userDropIcon">
                              <FontAwesomeIcon fixedWidth icon="exchange-alt" />
                            </div>
                            <div className="userName">Zmień program</div>
                          </DropdownItem>
                        </Link>
                      )}
                      {profileMenu}
                      <Link onClick={this.close} to="/change-password/">
                        <DropdownItem
                          tag="div"
                          onClick={() => {
                            this.close();
                          }}
                          className="userDropdownItem">
                          <div className="userDropIcon">
                            <FontAwesomeIcon fixedWidth icon="key" />
                          </div>
                          <div className="userName">Zmień hasło</div>
                        </DropdownItem>
                      </Link>
                      <DropdownItem
                        tag="div"
                        onClick={() => {
                          this.close();
                          this.props.logout();
                        }}
                        className="userDropdownItem">
                        <div className="userDropIcon">
                          <FontAwesomeIcon fixedWidth icon="sign-out-alt" />
                        </div>
                        <div className="userName">Wyloguj się</div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </React.Fragment>
              )}
              {!luxmed && !this.props.isAuthenticated && (
                <React.Fragment>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      onClick={this.close}
                      to="/login/"
                      className="loginRegister">
                      {!isOrange && (
                        <FontAwesomeIcon
                          fixedWidth
                          icon="sign-in-alt"
                          style={iconsColor}
                        />
                      )}

                      {!isOrange && (
                        <span
                          style={
                            isNais || isRenomia
                              ? isNais
                                ? {
                                    letterSpacing: '0.8px',
                                    color: '#39AA2C',
                                    fontWeight: 'bolder'
                                  }
                                : {
                                    letterSpacing: '0.8px',
                                    color: '#003865',
                                    fontWeight: 'bolder'
                                  }
                              : null
                          }>
                          Logowanie
                        </span>
                      )}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      onClick={this.close}
                      to="/register/"
                      className="loginRegister">
                      {!isOrange && (
                        <FontAwesomeIcon
                          fixedWidth
                          icon="user-friends"
                          style={iconsColor}
                        />
                      )}

                      {!isOrange && (
                        <span
                          style={
                            isNais || isRenomia
                              ? isNais
                                ? {
                                    letterSpacing: '0.8px',
                                    color: '#39AA2C',
                                    fontWeight: 'bolder'
                                  }
                                : {
                                    letterSpacing: '0.8px',
                                    color: '#003865',
                                    fontWeight: 'bolder'
                                  }
                              : null
                          }>
                          Rejestracja
                        </span>
                      )}
                    </NavLink>
                  </NavItem>
                </React.Fragment>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const programs = state.auth.programs || [];
  const name = state.menu.data && state.menu.data.name;
  return {
    isAuthenticated: state.auth.token !== null,

    username: name,
    luxmed:
      state.auth.user.programId === 'f1912316-3516-44a0-b58b-4c24b584f486',
    hasPrograms: programs.length > 1 ? true : false,
    menu: state.menu.data && state.menu.data.menu ? state.menu.data.menu : []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
