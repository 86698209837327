import React from 'react';

const DokumentyDoPobrania = (props) => {
  return (
    <React.Fragment>
      <h3 className="page-header firstColor">Dokumenty do pobrania</h3>

      <ol className="firstColor">
        <li>
          <a
            target="_blank"
            href="/public-assets/luxmed2023/OWU_GR_G0022022US+zał._1,_2.pdf">
            OWU
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="/public-assets/luxmed2023/Karta_produktu.pdf">
            Karta produktu
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.luxmed.pl/szpitale">
            Lista szpitali
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="/public-assets/luxmed2023/Pełna_Opieka_-_ulotka.pdf">
            Ulotka
          </a>
        </li>
      </ol>
    </React.Fragment>
  );
};

export default DokumentyDoPobrania;
