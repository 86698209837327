import React from 'react';
import logo from './../../../assets/img/brand/pzu.png';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <h4 className="firstColor">
        <b>Portal Cash</b>
        <br />
        Atrakcyjne pożyczki, kredyty i produkty oszczędnościowe od Grupy PZU
      </h4>

      <p className="mt-4 firstColor">
        <b>5 sytuacji, w których portal Cash może Ci pomóc</b>{' '}
      </p>
      <ul className="firstColor">
        <li>
          Potrzebujesz dodatkowej gotówki na swoje wydatki - bez poręczycieli i
          zbędnych formalności
        </li>
        <li>
          Spłata obecnych pożyczek i kredytów jest dla Ciebie trudna –
          miesięczne raty pochłaniają zbyt dużą część Twojego budżetu{' '}
        </li>
        <li>
          Myślisz o zakupie czy remoncie domu lub mieszkania i potrzebujesz
          sprawdzonego źródła sfinansowania tych wydatków
        </li>
        <li>
          Chcesz odkładać na przyszłość lub szukasz pomysłu na swoje
          oszczędności
        </li>
        <li>
          Potrzebne Ci wygodne konto osobiste z kartą i aplikacją na telefon
        </li>
      </ul>

      <br />
      <p className="firstColor">
        <b>Przejdź do portalu Cash. Wybierz jedną z opcji:</b>
      </p>

      <div className="d-flex justify-content-around">
        <a
          href="https://www.portalcash.pl/TVP?utm_source=uniqueservicepartners&utm_medium=umiejscowieniereklamy&utm_campaign=partnerships"
          target="_blank"
          className="btn btn-md btn-primary d-flex align-items-center"
          rounded>
          <span>
            <b>Masz umowę o pracę z TVP</b>
          </span>
        </a>
        <a
          href="https://www.portalcash.pl/USP"
          target="_blank"
          className="btn btn-md btn-primary d-flex align-items-center">
          <b>
            Masz inną umowę niż umowa o pracę <br />
            lub jesteś członkiem rodziny <br />
            pracownika TVP
          </b>
        </a>
      </div>

      <br />
      <p className="firstColor mt-5">
        <b>Jeśli potrzebujesz pomocy zadzwoń: 22 566 00 06</b>
        <br />
        od poniedziałku do piątku, 8:00 - 18:00 (opłata zgodna z taryfą
        operatora)
      </p>
      <br />
      <div class="container">
        <div class="row">
          <div class="col-sm-3">
            <img src={logo} alt="pzu" style={{ width: '150px' }} />
          </div>
          <div>
            <p
              className="firstColor d-flex flex-column justify-content-center"
              style={{ height: '100%' }}>
              <b>Portal Cash – Twój zaufany partner</b>
              <b>
                Portalem Cash zarządza PZU Cash SA – spółka z Grupy PZU
              </b>{' '}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Ogolne;
