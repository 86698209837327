import React from 'react';
import photoC from './../../../assets/img/brand/luxmed/photo3.png';
import logo from './../../../assets/img/brand/luxmed/interrisk.png';

export const SecondTable = (props) => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img style={{ maxWidth: '250px' }} src={logo} alt={logo} />
            <br />
            <br />

            <h4 className="firstColor">HEJT STOP</h4>
            <p>
              <b>
                wsparcie psychologiczne, prawne i informatyczne, w zakresie mowy
                nienawiści i bezpieczeństwa w sieci
              </b>
            </p>
          </div>
          <div className="col-md-6">
            <img style={{ maxWidth: '400px' }} src={photoC} alt={photoC} />
          </div>
        </div>
      </div>
      <br />
      <br />
      <table className="table table-bordered secondColor">
        <tbody className="text-center">
          <tr className="table-primary">
            <th>
              <b>ZDARZENIA</b>
            </th>
            <th>
              <b>ZAKRES</b>
            </th>
            <th>
              <b>LIMIT ŚWIADCZEŃ</b>
            </th>
          </tr>
          <tr className="table-info">
            <td colSpan={12}>
              <b>Pomoc informatyczna</b>
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              <b>zwiększenie bezpieczeństwa na portalach społecznościowych</b>
            </td>
            <td className="text-left">
              <p>
                <b>Pomoc w zakresie:</b>
              </p>
              <ul>
                <li>
                  ustawienia hasła zgodnie z polityką bezpieczeństwa danego
                  portalu
                </li>
                <li>zabezpieczenia konta za pomocą weryfikacji dwuetapowej</li>
                <li>
                  przeanalizowania lokalizacji logowania do konta przez inne
                  osoby (nieautoryzowany dostęp)
                </li>
                <li>ustawienia prywatności (profile publiczne, prywatne)</li>
                <li>
                  usunięcia niepożądanych aplikacji, gier, witryn i reklam
                </li>
                <li>procesowania ewentualnych płatności w sieci</li>
                <li>
                  skonfigurowania powiadomień o nierozpoznanych logowaniach do
                  kont
                </li>
                <li>szyfrowania wiadomości z powiadomieniami</li>
                <li>
                  zdalnej pomocy specjalisty IT, polegającej na podjęciu próby
                  usunięcia lub zatrzymania dostępu do negatywnych lub
                  bezprawnie udostępnionych informacji o Ubezpieczonym na
                  urządzeniu Ubezpieczonego
                </li>
              </ul>
            </td>

            <td className="align-middle">
              4 zdarzenia w okresie ubezpieczenia
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              <b>włamanie na konto na portalu społecznościowym</b>
            </td>
            <td className="text-left">
              <p>
                <b>Przekazanie informacji:</b>
              </p>
              <ul>
                <li>jak postępować po kradzieży danych</li>
                <li>
                  jak zabezpieczyć pozostałe konta oraz dostępy, m.in. do
                  bankowości
                </li>
                <li>internetowej</li>
                <li>kogo zawiadomić o włamaniu</li>
                <li>o potrzebnych dokumentach, aby odzyskać profil</li>
                <li>jak unikać podobnych sytuacji w przyszłości</li>
              </ul>
            </td>
            <td className="align-middle">
              4 zdarzenia w okresie ubezpieczenia
            </td>
          </tr>
          <tr className="table-info">
            <td colSpan={12}>
              <b>Pomoc psychologiczna i pomoc prawna</b>
            </td>
          </tr>
          <tr className="text-left">
            <td>
              <ul>
                <b>
                  <li>znieważenie lub zniesławienie w sieci</li>
                  <li>
                    cyberbulling, cybermobbing (gnębienie w wirtualnej
                    przestrzeni)
                  </li>
                  <li>
                    agresywne, obraźliwe działania w sieci (np. komentarze lub
                    opinie na temat Ubezpieczonego)
                  </li>
                  <li>
                    rozpowszechnianie nieprawdziwych lub niepożądanych
                    prywatnych informacji w sieci lub rozpowszechnianie w sieci
                    zdjęć Ubezpieczonego
                  </li>
                  <li>
                    deepfake (czyli techniki obróbki obrazu, w której istnieje
                    możliwość dopasowania twarzy do innego ciała)
                  </li>
                  <li>szantaż</li>
                </b>
              </ul>
            </td>
            <td>
              <ul>
                <li>Pomoc psychologiczna - konsultacje psychologiczne</li>
                <li>
                  Pomoc prawna
                  <ul>
                    <li>
                      porady prawne, w procesie:
                      <ul>
                        <li>interwencyjnym</li>
                        <li>zgłoszenia przypadku do organów ścigania</li>
                        <li>zawezwania do zaprzestania działań</li>
                        <li>zawezwania do usunięcia wpisu lub komentarza</li>
                        <li>żądania blokady konta</li>
                        <li>mailowego lub społecznościowego</li>
                        <li>wezwania do naprawienia szkody</li>
                      </ul>
                    </li>
                    <li>
                      przekazanie wzoru pisma, przy:
                      <ul>
                        <li>sporządzeniu reklamacji, uzupełnieniu</li>
                        <li>
                          formularzy, powiadomień i zgłoszeń wymaganych do
                          powiadomienia o zaistnieniu zdarzenia w sieci
                        </li>
                        <li>
                          opracowaniu dokumentów do organów ścigania o
                          możliwości popełnienia przestępstwa
                        </li>
                        <li>
                          konkretyzacji roszczeń z tytułu zadośćuczynienia w
                          stosunku do sprawcy zachowania
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
            <td className="align-middle">
              2 zdarzenia
              <br /> w okresie ubezpieczenia, w tym w ramach jednego zdarzenia:
              <ul>
                <li>2 konsultacje psychologiczne po 30 min</li>
                <li>nielimitowane usługi z zakresu pomocy prawnej</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};
