import React from 'react';
import Table from './TableFinances';
import { Link } from 'react-router-dom';
import logo from './../../../assets/img/brand/luxmed/interrisk.png';
import photo1 from './../../../assets/img/brand/luxmed/m1.jpg';
import photo2 from './../../../assets/img/brand/luxmed/m2.jpg';
import photo3 from './../../../assets/img/brand/luxmed/m3.jpg';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-6 ">
            <img
              style={{
                maxWidth: '250px',
                padding: '20px 0'
              }}
              src={logo}
              alt={logo}
            />
          </div>
          <div className="col-md-6 text-right">
            <img
              src={photo1}
              alt={photo1}
              style={{
                maxWidth: '350px',
                borderRadius: '5px',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
              }}
            />
          </div>
        </div>
      </div>

      <h3>Dom Max</h3>
      <p className="firstColor" style={{ fontSize: '1.3rem' }}>
        <b>Dlaczego warto ubezpieczyć mieszkanie/dom w formule all risk</b>
      </p>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul>
              <li>
                <b>
                  All risks dla szukających np. maksymalnej ochrony, od
                  wszystkich zdarzeń: przepięcia, wiatr, zalania,
                </b>{' '}
                a także za szkody wyrządzone przez samego klienta, jego dzieci,
                domowe oraz dzikie zwierzęta
              </li>
              <li>
                <b>
                  Brak przeglądu technicznego budynku/lokalu nie wpływa na
                  decyzję o wypłacie odszkodowania
                </b>{' '}
                - limit odpowiedzialności to sumy główne na polisie
              </li>
              <li>
                <b>Powódź</b> – objęte ochroną bez dopłaty składki
              </li>
              <li>
                <b>Fotowoltaika </b>na budynkach, budowlach lub zainstalowane
                obok budynku mieszkalnego – objęte ochroną bez dopłaty składki
              </li>
              <li>
                <b>W zakresie podstawowym szkody w szklanych elementach</b> jak
                lustrzane drzwi w szafie, szyba od kominka czy indukcyjna płyta
                grzewcza – <b>objęte ochroną bez dopłaty składki</b>
              </li>
              <li>
                <b>Szkody własne </b>– pokrywamy szkody wyrządzone przez naszego
                klienta i osoby bliskie
              </li>
              <li>
                <b>Roztargnienie Klienta</b> objęte jest ochroną, pokrywamy
                szkody mające znamiona rażącego niedbalstwa
              </li>
              <li>
                <b>Lokal zastępczy bez limitu dni</b>
              </li>
              <li>
                <b>E-zbiory:</b> książki, muzyka, filmy
              </li>
              <li>
                Ubezpieczenie <b>rzeczy wypożyczonych</b> np. z klubu sportowego
              </li>
              <li>
                <b>Rzeczy w lodówce – ochrona na skutek rozmrożenia</b>
              </li>
              <li>
                W ramach <b>OC w życiu prywatnym odpowiedzialność za zalania</b>
                , bez względu na winę Klienta
              </li>
              <li>
                <b>Rabunek mienia ruchomego</b> podczas przeprowadzki
              </li>
              <li>
                <b>
                  W zakresie assistance dla domu zorganizujemy pomoc informatyka
                </b>{' '}
                przy instalacji oprogramowania, wyborze nowego urządzenia, i
                wiele innych.
                <br />
                <b>Pomoc dostępna jest 24 godziny na dobę.</b>
              </li>
              <li>
                Możliwość <b>ubezpieczenia domku letniskowego</b>
              </li>
            </ul>
          </div>

          <div
            className="col-md-6 d-flex flex-column align-items-center justify-content-between"
            style={{ maxHeight: '700px' }}>
            {/* <img
              src={photo1}
              alt={photo1}
              style={{ maxWidth: '400px', borderRadius: '3px' }}
            /> */}
            {/* <img
              src={photo2}
              alt={photo2}
              style={{ maxWidth: '250px', borderRadius: '3px' }}
            /> */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <Table />
      <br />
      <div className="text-center mt-5 mb-5">
        <img
          src={photo3}
          alt={photo3}
          style={{ maxWidth: '700px', borderRadius: '3px' }}
        />
      </div>
      <p
        className="mt-3 second-color"
        style={{ textAlign: 'justify', fontSize: '9px' }}>
        Wysokość składki w ubezpieczeniu Dom Max uzależniona jest od przedmiotu
        i zakresu ubezpieczenia, a także wysokości sum ubezpieczenia, okresu
        ubezpieczenia oraz formy ubezpieczenia. Wyłączenia i ograniczenia
        odpowiedzialności zawarte są w ogólnych warunkach ubezpieczenia Dom Max
        zatwierdzonych uchwałą nr 01/21/12/2021 Zarządu InterRisk Towarzystwo
        Ubezpieczeń Spółka Akcyjna Vienna Insurance Group z dnia 21 grudnia2021
        roku dostępnych na stronie www.interrisk.pl. InterRisk Towarzystwo
        Ubezpieczeń S.A. Vienna Insurance Group z siedzibą w Warszawie przy ul.
        St. Noakowskiego 22, wpisana do rejestru przedsiębiorców Krajowego
        Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w
        Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr
        KRS 0000054136, NIP 526-00-38-806, o kapitale zakładowym w
        kwocie137.640.100,00 PLN (opłacony w całości), prowadząca działalność
        ubezpieczeniową i reasekuracyjną w oparciu o zezwolenie Ministra
        Finansów DU/905/A/KP/93 z 5 listopada 1993 r. Propozycja ma charakter
        wyłącznie informacyjny i nie stanowi oferty w rozumieniu art. 66 kodeksu
        cywilnego.
      </p>

      {/* <h2 className="page-header">Ubezpieczenie Majątkowe</h2>

      <div className="row">
        <div className="col-sm-7">
          <br />
          <p className="text-justify">
            UNIQUE SERVICE PARTNER Sp. z o.o. prowadząca działalność
            ubezpieczeniową (nr agenta w KNF 11235054/A) w ramach posiadanych
            pełnomocnictw z Zakładami Ubezpieczeń oferuje Państwu ubezpieczenia
            komunikacyjne, majątkowe (mieszkań i domów), turystyczne oraz
            odpowiedzialności cywilnej w życiu prywatnym jak i z tytułu
            wykonywanego zawodu.
            <br />
            <br />W przypadku zainteresowania przedstawiciel naszej agencji
            skontaktuje się z Państwem w ciągu 48h.
          </p>
          <div className="text-center">
            <Link to="/majatkowe/kup" className="btn btn-lg btn-primary">
              Wypełnij zgłoszenie
            </Link>
          </div>
        </div>

        <div className="col-sm-5">
          <div className="thumbnail">
            <img src="/tvp/dashboard/11.jpg" alt="domki" />
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default Ogolne;
